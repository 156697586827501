import NotFound from "@/components/NotFound.vue";
import Login from "@/pages/Login.vue";
import Logout from "@/pages/Logout.vue";
import Analytical from "@/pages/reports/Analytical.vue";
import Empty from "@/pages/Empty.vue";
import AccessList from "@/pages/access/AccessList.vue";
import CommitmentList from "@/pages/commitments/CommitmentList.vue";
import CommitmentListV2 from "@/pages/commitments/CommitmentListV2.vue";
import Search from "@/pages/Search/Search.vue";
import ProjectLeads from "@/pages/ProjectLead/ProjectLeadList.vue";
import PortfolioManagers from "@/pages/PortfolioManager/PortfolioManagerList.vue";
import BatchList from "@/pages/Batch/BatchList.vue";
import Customers from "@/pages/customer/CustomerList.vue";
import Clients from "@/pages/client/ClientList.vue";
import TDMs from "@/pages/tdm/TDMList.vue";
import LineManagers from "@/pages/LineManager/LineManagerList.vue";
import Holiday from "@/pages/publicHoliday/Holiday.vue";
import ContributionReport from "../pages/reports/ContributionReport.vue";
import TimesheetList from "@/pages/timesheets/Timesheet.vue";
import PayRoll from "@/pages/reports/PayRoll.vue";
import AuditTrailList from "@/pages/audit/AuditTrailList.vue";
import Error from "@/pages/Error.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import UserList from "@/pages/users/UserList.vue";
import CustomerChart from "@/pages/customerChart/CustomerChart.vue";
import PayRun from "@/pages/payRan/PayRun.vue";
import HRCommitmentsReport from "@/pages/reports/HRCommitmentsReport.vue";
import ExpenseAnalysis from "@/pages/reports/ExpenseAnalysis.vue";
import TimesheetReport from "@/pages/reports/TimesheetReport.vue";
import LoanList from "@/pages/loan/LoanList.vue";
import VMList from "@/pages/vm/VMList.vue";
import ActivityWatch from "@/pages/reports/ActivityWatch.vue";
import Wazuh from "@/pages/reports/Wazuh.vue";
import ActivitiesList from "@/pages/activities/ActivitiesList.vue";
import WeeklyTimesheetAudit from "@/pages/weeklyTimesheetAudit/weeklyTimesheetAudit.vue";
import JobOpening from "@/pages/job/JobOpening.vue";
import TaskList from "@/pages/job/TaskList.vue";
import TaskListTemplate from "@/pages/job/TaskListTemplate.vue";
import BulkImportExport from "@/pages/bulkImportExport/BulkImportExport.vue";
import { RouteRecordRaw } from "vue-router";
import RepositoryList from "@/pages/repository/Repository.vue";
import BillableItemList from "@/pages/billableItem/BillableItemList.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "First",
    meta: {
      title: "Admin Panel",
      authorize: true,
    },
    component: Empty,
  },
  {
    path: "/resetPassword:token?",
    name: "resetPassword",
    meta: {
      title: "Reset password",
      authorize: false,
    },
    component: ResetPassword,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login",
      authorize: false,
    },
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authorize: false,
    },
    component: Logout,
  },
  {
    path: "/reports/analytical-report",
    name: "analyticReport",
    meta: {
      title: "Analytical report",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: Analytical,
  },

  {
    path: "/reports/timesheet-report",
    name: "timesheetReport",
    meta: {
      title: "Timesheet Report",
      authorize: true,
      roles: ["Admin"],
    },
    component: TimesheetReport,
  },

  {
    path: "/weeklyTimesheetAudit",
    name: "weeklyTimesheetAudit",
    meta: {
      title: "Weekly Timesheet Audit",
      authorize: true,
      roles: ["Admin"],
    },
    component: WeeklyTimesheetAudit,
  },
  {
    path: "/vm",
    name: "vm",
    meta: {
      title: "VM",
      authorize: true,
      roles: ["Admin", "Support"],
    },
    component: VMList,
  },
  {
    path: "/vm/activityWatch/:id",
    name: "VMActivityWatch",
    meta: {
      title: "VMActivityWatch",
      authorize: true,
      roles: ["Admin", "Support"],
    },
    component: ActivityWatch,
  },

  {
    path: "/vm/wazuh/:id",
    name: "VMWazuh",
    meta: {
      title: "VMWazuh",
      authorize: true,
      roles: ["Admin"],
    },
    component: Wazuh,
  },
  {
    path: "/loans",
    name: "loans",
    meta: {
      title: "Loans",
      authorize: true,
      roles: ["Admin"],
    },
    component: LoanList,
  },
  {
    path: "/auditTrail",
    name: "auditTrail",
    meta: {
      title: "Audit Trail",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: AuditTrailList,
  },
  {
    path: "/reports/contribution-report",
    name: "contributionReport",
    meta: {
      title: "Contribution report",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: ContributionReport,
  },
  {
    path: "/reports/hrcommitments-report",
    name: "hrCommitmentsReport",
    meta: {
      title: "HR Commitments Report",
      frontAuthRequired: true,
      authorize: true,
      roles: ["Admin"],
    },
    component: HRCommitmentsReport,
  },
  {
    path: "/reports/expenseAnalysis",
    name: "expenseAnalysis",
    meta: {
      title: "Expense Analysis",
      authorize: true,
      roles: ["Admin"],
    },
    component: ExpenseAnalysis,
  },
  {
    path: "/Error",
    name: "error",
    meta: {
      title: "Error",
      errorHandler: true,
    },
    component: Error,
  },
  {
    path: "/users/list",
    name: "usersList",
    meta: {
      title: "Users List",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: UserList,
  },
  {
    path: "/notifications",
    name: "notificationList",
    meta: {
      title: "Notification List",
      authorize: true,
      roles: ["Admin"],
    },
    component: BatchList,
  },
  {
    path: "/billableItem/list",
    name: "billableItemList",
    meta: {
      title: "Billable Item list",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: BillableItemList,
  },
  {
    path: "/timesheet/list",
    name: "listTimesheet",
    meta: {
      title: "Timesheet list",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: TimesheetList,
  },
  {
    path: "/access/list",
    name: "accessList",
    meta: {
      title: "Access list",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin", "Linemanager"],
    },
    component: AccessList,
  },
  {
    path: "/activities/list",
    name: "activityList",
    meta: {
      title: "Activity list",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: ActivitiesList,
  },
  {
    path: "/commitment/list",
    name: "commitmentList",
    meta: {
      title: "Commitment list",
      authorize: true,
      roles: ["Admin"],
    },
    component: CommitmentList,
  },
  {
    path: "/commitment/listV2",
    name: "commitmentListV2",
    meta: {
      title: "Commitment list",
      authorize: true,
      roles: ["Admin"],
    },
    component: CommitmentListV2,
  },
  {
    path: "/search",
    name: "searchTeammember",
    meta: {
      title: "Search",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin", "Hiringmanager"],
    },
    component: Search,
  },
  {
    path: "/job",
    name: "jobOpening",
    meta: {
      title: "Job",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin", "Hiringmanager"],
    },
    component: JobOpening,
  },
  {
    path: "/task",
    name: "taskList",
    meta: {
      title: "Task",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin", "Hiringmanager"],
    },
    component: TaskList,
  },
  {
    path: "/taskTemplate",
    name: "TaskListTemplate",
    meta: {
      title: "Task",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin", "Hiringmanager"],
    },
    component: TaskListTemplate,
  },
  {
    path: "/bulkImportExport",
    name: "BulkImportExport",
    meta: {
      title: "Task",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: BulkImportExport,
  },
  {
    path: "/publicHolidays",
    name: "publicHolidays",
    meta: {
      title: "Holidays",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: Holiday,
  },

  {
    path: "/repositories",
    name: "repositories",
    meta: {
      title: "Repositories",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: RepositoryList,
  },
  {
    path: "/customers/chart",
    name: "customersChart",
    meta: {
      title: "Customers Chart",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: CustomerChart,
  },
  {
    path: "/payRuns",
    name: "Pay Runs",
    meta: {
      title: "Pay Runs",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: PayRun,
  },
  {
    path: "/reports/pay-roll",
    name: "PayRoll Report",
    meta: {
      title: "PayRoll Report",
      authorize: true,
      frontAuthRequired: true,
      roles: ["Admin"],
    },
    component: PayRoll,
  },
  {
    path: "/projectLeads",
    name: "Project Leads",
    meta: {
      title: "Project Leads",
      authorize: true,
      roles: ["Admin"],
    },
    component: ProjectLeads,
  },
  {
    path: "/PortfolioManagers",
    name: "Portfolio Managers",
    meta: {
      title: "Portfolio Managers",
      authorize: true,
      roles: ["Admin"],
    },
    component: PortfolioManagers,
  },
  {
    path: "/customers",
    name: "Customers",
    meta: {
      title: "Customers",
      authorize: true,
      roles: ["Admin"],
    },
    component: Customers,
  },
  {
    path: "/clients",
    name: "Clients",
    meta: {
      title: "Clients",
      authorize: true,
      roles: ["Admin"],
    },
    component: Clients,
  },
  {
    path: "/tdms",
    name: "TDMs",
    meta: {
      title: "TDMs",
      authorize: true,
      roles: ["Admin"],
    },
    component: TDMs,
  },
  {
    path: "/lineManagers",
    name: "Line Managers",
    meta: {
      title: "Line Managers",
      authorize: true,
      roles: ["Admin"],
    },
    component: LineManagers,
  },
  {
    path: "/:pathMatch(.*)",
    meta: {
      title: "404 Not Found",
      authorize: false,
    },
    component: NotFound,
  },
];
