<template>
  <v-row>
    <v-col v-show="!pageLoading" md="12">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            Commitment List
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="newItem"> <v-icon>mdi-plus</v-icon> New </v-btn>
            <v-dialog v-if="showNewItem" v-model="showNewItem" persistent fullscreen @keydown.esc="showNewItem = false">
              <NewCommitmentV2
                ref="newCommitment"
                :commitments="desserts"
                :commitmentModel="editItem"
                @cancel="showNewItem = false"
                @saved="commitmentSaved"
              ></NewCommitmentV2>
            </v-dialog>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="align-center">
            <v-col md="2">
              <SelectField
                :items="customers"
                item-title="FullName"
                item-value="id"
                v-model="selectedCustomer"
                label="Customer"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col md="2">
              <SelectField
                hide-details
                :items="projects"
                item-title="Name"
                item-value="id"
                v-model="selectedProject"
                label="Project"
                outlined
                dense
              />
            </v-col>
            <v-col md="2">
              <SelectField
                :items="teammembers"
                item-title="FullName"
                item-value="Id"
                v-model="selectedTeammember"
                label="Team Member"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col md="2">
              <SelectField
                :items="linemanagers"
                item-title="FirstName"
                item-value="id"
                v-model="selectedLineManager"
                label="Line Manager"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col md="2">
              <v-switch hide-details label="Active" class="mt-8" v-model="isActive" color="success"></v-switch>
            </v-col>
            <v-col md="2">
              <v-btn color="secondary" class="mt-6" @click="clearFilters()"> <v-icon>mdi-close</v-icon> Clear </v-btn>
            </v-col>
          </v-row>
          <v-data-table :items="tempDesserts" :headers="headers" :loading="loading" :custom-sort="customSort">
            <template v-slot:item.Name="{ item }">
              <strong>{{ item.Name }}</strong>
            </template>
            <template v-slot:item.Status="{ item }">
              <v-chip v-if="checkDate(item) == 0" class="ma-2" color="success"> Active </v-chip>
              <v-chip v-if="checkDate(item) == 2" class="ma-2" color="error"> Inactive </v-chip>
              <v-chip v-if="checkDate(item) == 1" class="ma-2" color="error"> Expired </v-chip>
              <v-chip v-if="checkDate(item) == 3" class="ma-2" color="yellow"> Upcoming </v-chip>
            </template>
            <template v-slot:item.TeamMembers="{ item }">
              {{ formatTeammembers(item) }}
            </template>
            <template v-slot:item.StartDate="{ item }">
              {{ formatDate(item.StartDate) }}
            </template>
            <template v-slot:item.EndDate="{ item }">
              {{ formatDate(item.EndDate) }}
            </template>
            <template v-slot:item.Projects="{ item }">
              {{ formatProjects(item) }}
            </template>
            <template v-slot:item.Customers="{ item }">
              {{ formatCustomers(item) }}
            </template>
            <template v-slot:item.Type="{ item }">
              {{ item.HoursPerWeekRadio ? "Hours Per week" : "Casual" }}
              <div v-if="item.HoursPerWeekRadio"><strong>Min: </strong>{{ item.HoursPerWeekMin }}</div>
              <div v-if="item.HoursPerWeekRadio"><strong>Max: </strong>{{ item.HoursPerWeekMax }}</div>
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-menu offset-y location="bottom" scroll-strategy="close">
                <template v-slot:activator="{ props }">
                  <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
                </template>
                <v-list>
                  <v-list-item @click="edit(item)" title="Edit" />
                  <v-list-item @click="remove(item)" title="Delete" />
                  <v-list-item @click="clone(item)" title="Clone" />
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false">
      <confirm-dialog :message="confirmModel" @confirm="confirmRemove" @cancel="cancelRemove"></confirm-dialog>
    </v-dialog>
    <v-dialog v-if="ShowClone" v-model="ShowClone" persistent max-width="500" @keydown.esc="ShowClone = false">
      <clone-commitment ref="cloneCommitment" :commitment="cloningItem" @cancel="ShowClone = false" @clone="cloneCommitment"></clone-commitment>
    </v-dialog>
    <loading-component v-if="pageLoading"></loading-component>
  </v-row>
</template>
<script lang="ts">
import Commitment from "shared-components/src/models/Commitment";
import NewCommitmentV2 from "./NewCommitmentV2.vue";
import CloneCommitment from "./CloneCommitment.vue";
import CustomerService from "shared-components/src/services/CustomerService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import LineManagerService from "shared-components/src/services/LineManagerService";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import { Teammember } from "shared-components/src/models/Teammember";
import LineManager from "shared-components/src/models/LineManager";
import Project from "shared-components/src/models/Project";
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import AppHelper from "shared-components/src/utils/AppHelper";
import { TeammemberWithSummaryDetails } from "shared-components/src/models/Teammember";
import ManagerModel from "shared-components/src/models/ManagerModel";
import { ProjectApi } from "shared-components/src/services/openApi/api";
import store from "@/store";

export default defineComponent({
  components: {
    ConfirmDialog,
    LoadingComponent,
    CloneCommitment,
    NewCommitmentV2,
  },
  data() {
    return {
      ShowClone: false,
      pageLoading: true,
      totalDesserts: 0,
      editItem: null as Commitment | null,
      linemanagers: [] as LineManager[],
      teammembers: [] as Teammember[] | TeammemberWithSummaryDetails[],
      customers: [] as ManagerModel[],
      projects: [] as Project[],
      desserts: [] as Commitment[],
      tempDesserts: [] as Commitment[],
      cloningItem: null as Commitment | null,
      selectedLineManager: "",
      selectedTeammember: "",
      selectedCustomer: "",
      selectedProject: "",
      isActive: false,
      confirmModel: {
        title: "",
        text: "",
      },
      loading: true,
      showNewItem: false,
      showConfirm: false,
      headers: [
        { title: "Name", align: "start", sortable: true, value: "Name" },
        { title: "Customers", value: "Customers", sortable: false },
        { title: "Projects", value: "Projects", sortable: false },
        { title: "TeamMembers", sortable: false, value: "TeamMembers" },
        { title: "Start date", sortable: true, value: "StartDate" },
        { title: "End date", sortable: true, value: "EndDate" },
        { title: "Status", sortable: true, value: "Status" },
        { title: "Type", value: "Type", sortable: false },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
      selectedItem: null as Commitment | null,
      selectedId: null as string | null,
    };
  },
  async mounted() {
    await this.fetchCommitments();
    await this.fetchTeammembers();
    await this.fetchProjects();
    await this.fetchCustomers();
    await this.fetchLinemanagers();
  },
  methods: {
    getCommitmentObject(item: any) {
      var commitment = { ...item.commitment };
      var customerNamesArray = [] as string[];
      var projectNamesArray = [] as string[];
      var teammemberNamesArray = [] as string[];
      this.customers.forEach((c) => {
        if (commitment.CustomerIds.includes(c.id)) {
          customerNamesArray.push(c.FullName);
        }
      });
      this.projects.forEach((c) => {
        if (commitment.ProjectIds.includes(c.id)) {
          projectNamesArray.push(c.Name);
        }
      });
      this.teammembers.forEach((c, index) => {
        if (commitment.TeamMemberIds.includes(c.Id)) {
          teammemberNamesArray.push(c.FirstName);
        }
      });
      if (commitment.StartDate && commitment.EndDate) {
        var customerNames = customerNamesArray.join(", ");
        var projectNames = projectNamesArray.join(", ");
        var teammemberNames = teammemberNamesArray.join(", ");
        commitment.Name = `${customerNames}-${projectNames}-${Utils.getYear(commitment.StartDate)}-${Utils.getMonthName(
          commitment.StartDate
        )}-${Utils.getMonthName(commitment.EndDate)}-${teammemberNames}`;
      }
      return commitment;
    },
    async cloneCommitment(cloneRes: any) {
      var commitmentObject = this.getCommitmentObject(cloneRes);
      if (commitmentObject) {
        if (cloneRes.expireOld) {
          var updateResult = await CommitmentService.update(commitmentObject);
          this.commitmentSaved(updateResult);
        } else {
          commitmentObject.id = "";
        }
        this.editItem = commitmentObject;
        this.ShowClone = false;
        this.showNewItem = true;
        setTimeout(async () => {
          await (this.$refs.newCommitment as any).loadData();
        }, 500);
      }
    },
    clone(model: any) {
      this.cloningItem = model;
      this.ShowClone = true;
    },
    remove($item: Commitment): void {
      this.confirmModel.title = `Remove Commitment(${$item.Name})`;
      this.confirmModel.text = "Are you sure?";
      this.showConfirm = true;
      this.selectedItem = $item;
    },
    async confirmRemove(): Promise<void> {
      this.showConfirm = false;
      if (this.selectedItem) {
        const idx = this.desserts.findIndex((x) => x.id === (this.selectedItem ? this.selectedItem.id : ""));
        const tempIdx = this.tempDesserts.findIndex((x) => x.id === (this.selectedItem ? this.selectedItem.id : ""));
        const result = await CommitmentService.remove(this.selectedItem.id);
        if (result) {
          this.desserts.splice(idx, 1);
          this.tempDesserts.splice(tempIdx, 1);
          this.selectedItem = null;
        } else {
          store.dispatch(
            "showErrorMessage",
            "you can't delete this commitment because there is one or more timesheet associated to this commitment!"
          );
        }
      }
    },
    cancelRemove(): void {
      this.showConfirm = false;
      this.selectedItem = null;
    },
    async fetchCommitments(): Promise<void> {
      this.loading = true;
      this.desserts = await CommitmentService.getList();
      this.tempDesserts = this.desserts;
      this.loading = false;
    },
    async fetchTeammembers() {
      try {
        const theDate = new Date();
        theDate.setMonth(theDate.getMonth() - 2);
        const activeCommitments = this.tempDesserts.filter((c) => c.EndDate && c.EndDate >= theDate);
        if (AppHelper.IsAzureEnv()) {
          TeamMemberService.getListWithSummaryDetails()
            .then((items: any) => {
              this.teammembers = items.filter(
                (c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim() && this.isActiveTeammember(c, activeCommitments)
              );
              this.teammembers.splice(0, 0, { Id: "", FullName: "All" } as TeammemberWithSummaryDetails);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        } else {
          TeamMemberService.getList(true)
            .then((items: any) => {
              this.teammembers = items
                .filter(
                  (c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim() && this.isActiveTeammember(c, activeCommitments)
                )
                .map((item: any) => {
                  item.FullName = `${item.FirstName} ${item.LastName}`;
                  return item;
                });
              this.teammembers.splice(0, 0, { Id: "", FullName: "All" } as Teammember);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        }
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    async fetchLinemanagers() {
      try {
        LineManagerService.getList(true)
          .then((items: any) => {
            this.linemanagers = items
              .filter((c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
              .map((item: any) => {
                item.FirstName = `${item.FirstName} ${item.LastName}`;
                return item;
              });
            this.linemanagers.splice(0, 0, { id: "", FirstName: "All" } as LineManager);
          })
          .catch((err: any) => this.$emit("errorRaised", err));
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    async fetchCustomers() {
      try {
        CustomerService.getList(true)
          .then((items: any) => {
            this.customers = items
              .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
              .map((item: any) => {
                if (item.FirstName && item.FirstName.trim() && item.LastName && item.LastName.trim()) {
                  item.FullName = `${item.FirstName} ${item.LastName}`;
                } else {
                  if (item.FirstName && item.FirstName.trim()) {
                    item.FullName = item.FirstName;
                  } else {
                    item.FullName = item.LastName;
                  }
                }

                return item;
              });
            this.customers.splice(0, 0, { id: "", FullName: "All" } as ManagerModel);
            this.pageLoading = false;
          })
          .catch((err: any) => this.$emit("errorRaised", err));
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    async fetchProjects() {
      try {
        new ProjectApi()
          .getOnlyProjects()
          .then((items: any) => {
            var porjectList = items.data as Project[];
            this.projects = porjectList.filter((c) => c.Name && c.Name.trim().length > 0);
            this.projects.splice(0, 0, { id: "", Name: "All" } as Project);
          })
          .catch((err: any) => this.$emit("errorRaised", err));
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    formatTeammembers(item: Commitment): string {
      return item && item.TeamMembers ? item.TeamMembers.map((item: any) => item.TmFullName).join(", ") : "";
    },
    formatProjects(item: Commitment): string {
      return item && item.ProjectDetails ? item.ProjectDetails.map((item: any) => item.Name).join(", ") : "";
    },
    formatCustomers(item: Commitment): string {
      return item && item.Customers ? item.Customers.map((item: any) => item.CuFullName).join(", ") : "";
    },
    isActiveTeammember(teammember: any, activeCommitments: any) {
      return activeCommitments.some((c: any) => c.TeamMemberIds && c.TeamMemberIds.includes(teammember.Id));
    },
    checkDate(item: any) {
      const today = Utils.setDateTimes(new Date(), 0, 0, 0, 0) as Date;
      if (!item.IsActive) {
        return 2; // Inactive
      }
      if (item.EndDate && item.EndDate < today) {
        return 1; // Expired
      }
      if (item.StartDate && item.StartDate > today) {
        return 3; // Upcoming
      }
      return 0; // Active
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    async edit($item: Commitment): Promise<void> {
      this.editItem = $item;
      this.showNewItem = true;
      setTimeout(async () => {
        await (this.$refs.newCommitment as any).loadData();
      }, 500);
    },
    newItem(): void {
      this.editItem = null;
      this.showNewItem = true;
      setTimeout(async () => {
        await (this.$refs.newCommitment as any).loadData();
      }, 500);
    },
    commitmentSaved($model: Commitment): void {
      if (!$model.id) {
        store.dispatch("showErrorMessage", "you can't edit End Date because there is one or more timesheet after new End Date!");
      } else {
        this.showNewItem = false;
        this.fetchCommitments();
      }
    },
    clearFilters() {
      this.selectedTeammember = "";
      this.selectedCustomer = "";
      this.selectedProject = "";
      this.selectedLineManager = "";
      this.isActive = false;
    },
    filterDesserts() {
      return this.desserts.filter((item) => {
        let exist = true;
        if (this.selectedTeammember) {
          exist = item.TeamMemberIds.includes(this.selectedTeammember);
          if (!exist) {
            return;
          }
        }
        if (this.selectedCustomer) {
          exist = item.CustomerIds.includes(this.selectedCustomer);
          if (!exist) {
            return;
          }
        }
        if (this.selectedProject) {
          exist = item.ProjectIds.includes(this.selectedProject);
          if (!exist) {
            return;
          }
        }
        if (this.selectedLineManager) {
          exist = item.LineManagerId === this.selectedLineManager;
          if (!exist) {
            return;
          }
        }
        if (this.isActive) {
          const theDate = new Date();
          exist =
            item.IsActive &&
            ((item.StartDate && item.StartDate <= theDate && item.EndDate && item.EndDate >= theDate) ||
              (!item.StartDate && item.EndDate && item.EndDate >= theDate))
              ? true
              : false;
          if (!exist) {
            return;
          }
        }
        if (exist) {
          return item;
        }
      });
    },
    customSort(items: any, index: any, isDesc: any) {
      items.sort((a: any, b: any) => {
        if (index[0] === "StartDate" || index[0] === "EndDate") {
          if (!isDesc[0]) {
            return new Date(a[index]).getTime() - new Date(b[index]).getTime();
          } else {
            return new Date(b[index]).getTime() - new Date(a[index]).getTime();
          }
        }
        if (index[0] === "Status") {
          if (!isDesc[0]) {
            return this.checkDate(a) < this.checkDate(b) ? -1 : 1;
          } else {
            return this.checkDate(b) < this.checkDate(a) ? -1 : 1;
          }
        } else if (typeof a[index] !== "undefined") {
          if (index[0] === "SeniorityLevel") {
            if (!isDesc[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
            }
          } else {
            if (index[0] === "Name") {
              if (!isDesc[0]) {
                return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              } else {
                return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
        }
      });
      return items;
    },
  },
  watch: {
    selectedTeammember() {
      this.tempDesserts = this.filterDesserts();
    },
    selectedCustomer() {
      this.tempDesserts = this.filterDesserts();
    },
    selectedProject() {
      this.tempDesserts = this.filterDesserts();
    },
    selectedLineManager() {
      this.tempDesserts = this.filterDesserts();
    },
    isActive() {
      this.tempDesserts = this.filterDesserts();
    },
  },
});
</script>
