<template>
  <v-card>
    <v-card-title>
      <v-btn icon @click="closeDialog" class="ml-auto">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container>
        <span class="headline">{{ selectedRow.TaskTitle }}</span>
      </v-container>

      <v-divider></v-divider>

      <v-container class="py-4">
        <v-row align="center">
          <v-col cols="auto">
            <v-avatar color="primary" size="12"></v-avatar>
          </v-col>
          <v-col>
            <span class="task-status">{{ item.Status }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="task-details">
            <v-icon class="mr-1">mdi-subtitles</v-icon>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="mr-4">{{ selectedRow.PositionTitle }}</span>
              </template>
              <span>Job Opening Name</span>
            </v-tooltip>
            <v-icon class="mr-1">mdi-account-tie</v-icon>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="mr-8">{{ selectedRow.HiringManagerName }}</span>
              </template>
              <span>Hiring Manager Name</span>
            </v-tooltip>
            <v-icon class="mr-1">mdi-calendar</v-icon>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.Date }}</span>
              </template>
              <span>Date</span>
            </v-tooltip>
            <v-icon class="ml-4 mr-1">mdi-format-list-bulleted</v-icon>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="mr-1">Order </span><span>{{ item.OrderNumber }}</span>
              </template>
              <span>Order Number</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="task-section">
            <span class="task-title">Description</span>
            <p>{{ item.Description }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="task-section">
            <div class="comment-part">
              <span class="task-title">Comment</span>
            </div>
            <div v-for="comment in comments" :key="comment.id">
              <p>{{ comment.Text }}</p>
            </div>
          </v-col>

          <v-col cols="12">
            <TextAreaField label="Message" type="text" v-model="message" outlined></TextAreaField>
          </v-col>
          <v-col cols="12" class="comment-button">
            <v-btn color="primary" @click="confirm"> <v-icon>mdi-check</v-icon> Add Commnet </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  PositionApi,
  SendNotificationRequestModel,
  NotificationApi,
  PositionTaskDetailResponseModel,
} from "shared-components/src/services/openApi/api";
import TextAreaField from "shared-components/src/components/Fields/TextAreaField.vue";

const positionApi = new PositionApi();
const notificationApi = new NotificationApi();
export default defineComponent({
  components: { TextAreaField },
  props: {
    item: {
      type: Object as () => PositionTaskDetailResponseModel,
      default: () => ({} as PositionTaskDetailResponseModel),
    },
    selectedRow: {
      type: Object as () => PositionTaskDetailResponseModel,
      default: null,
    },
    isHiringmanager: {
      type: Boolean,
    },
  },
  mounted() {
    this.getcomments();
  },
  data() {
    return {
      message: "",
      commentData: {} as SendNotificationRequestModel,
      comments: [] as Array<{ id: any; Text: string }>,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    async getcomments() {
      try {
        const response = await notificationApi.getPositionTaskNotifications(this.item.Id as string);

        if (response.data && Array.isArray(response.data)) {
          this.comments = response.data.map((comment: any) => ({
            id: comment.id,
            Text: comment.Text,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },
    async confirm() {
      this.commentData = {
        message: this.message,
        receiverType: "hiringManager",
      };

      try {
        const response = await positionApi.sendPositionTaskNotification(this.item.Id as string, this.commentData);
        this.comments.push({ id: response.data.id, Text: this.message });

        this.message = "";
      } catch (error) {
        console.error("Failed to save comment:", error);
      }
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
  padding-left: 8px;
  font-size: 1.5rem;
}

.task-status {
  font-weight: bold;
  font-size: 1.2rem;
}

.task-details {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 1rem;
}

.task-section {
  margin-top: 16px;
}

.task-title {
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}

.comment-part {
  display: flex;
  justify-content: space-between;
}

.comment-button {
  display: flex;
  justify-content: end;
}
</style>
