<template>
  <v-card>
    <v-card-title> {{ model && model.id ? "Edit" : "Add" }} Line Manager </v-card-title>
    <v-card-text>
      <v-form ref="frmRegister" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required]" outlined label="First Name" v-model="model.FirstName" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required]" outlined label="Last Name" v-model="model.LastName" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required, rules.email]" outlined label="Email" v-model="model.Email" />
          </v-col>
          <v-col md="6" sm="6" class="12 pt-0 pb-0">
            <v-switch class="mt-1" v-model="model.IsActive" label="Is Active"></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn class="primary mr-10" @click="save" :loading="loading"><v-icon>mdi-check</v-icon> Save</v-btn>
        <v-btn class="secondary" @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import LineManagerService from "shared-components/src/services/LineManagerService";
import LineManager from "shared-components/src/models/LineManager";

export default defineComponent({
  props: ["selectedLineManager"],
  data() {
    return {
      valid: true,
      model: {
        id: "",
        FirstName: "",
        LastName: "",
        Email: "",
        IsActive: false,
      },
      pageLoading: true,
      loading: false,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        email: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
      },
    };
  },
  async mounted() {
    if (this.selectedLineManager && this.selectedLineManager.id) {
      this.model.id = this.selectedLineManager.id;
      this.model.FirstName = this.selectedLineManager.FirstName;
      this.model.LastName = this.selectedLineManager.LastName;
      this.model.Email = this.selectedLineManager.Email;
      this.model.IsActive = this.selectedLineManager.IsActive;
    }
  },
  methods: {
    cancel(): void {
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmRegister as any).validate();
      if (isValid.valid) {
        this.loading = true;
        const item = { ...this.model } as LineManager;
        if (item.id) {
          await LineManagerService.update(item);
        } else {
          const savedLineManagerId = await LineManagerService.set(item);
          item.id = savedLineManagerId;
        }
        this.loading = false;
        this.$emit("saved", item);
      }
    },
  },
});
</script>
