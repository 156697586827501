<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <span> Add NotificationCreate new User</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel" fab class="error" x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="frmUser" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" xs="6" sm="6" md="6">
            <TextField :rules="[rules.required]" v-model="model.firstName" label="First name" outlined></TextField>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <TextField :rules="[rules.required]" v-model="model.lastName" label="Last name" outlined></TextField>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <TextField :rules="[rules.required, rules.email]" v-model="model.email" label="Email" outlined></TextField>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <TextField :rules="[rules.required]" v-model="model.password" label="Password" outlined></TextField>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <SelectField
              :rules="[rules.required]"
              v-model="model.role"
              chips
              :items="['Admin', 'Hiringmanager', 'Support', 'Teammember', 'Customer', 'LineManager', 'ProjectLead', 'PortfolioManager', 'SalesRep']"
              outlined
              label="Roles"
            >
            </SelectField>
          </v-col>
          <v-col cols="12" sm="12" md="12" v-if="hasCustomerRole">
            <SelectField
              v-model="model.customers"
              :rules="[validateCustomers]"
              multiple
              chips
              item-title="Name"
              item-value="id"
              :items="customers"
              outlined
              label="User's Customer(s)"
            ></SelectField>
          </v-col>
        </v-row>
      </v-form>
      <v-snackbar left :timeout="5000" color="error" v-model="showError">{{ errorText }}</v-snackbar>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn color="primary" class="mr-5" variant="elevated" :loading="loading" @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
        <v-btn class="secondary_btn" variant="elevated" :disabled="loading" @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import CustomerService from "shared-components/src/services/CustomerService";
import { UserCreateModel } from "@/services/UserService";
import UserService from "@/services/UserService";

export default defineComponent({
  data() {
    return {
      loading: false,
      customers: [] as Array<{ id: string; Name: string }>,
      model: {
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        role: "",
        customers: [],
      } as UserCreateModel,
      errorText: null,
      showError: false,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        email: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid.",
      },
    };
  },
  async mounted() {
    this.customers = (await CustomerService.getList()).map((c: any) => {
      return { id: c.id, Name: `${c.LastName} ${c.FirstName}` };
    });
  },
  computed: {
    hasCustomerRole(): boolean {
      return this.model.role === "Customer";
    },
  },
  methods: {
    validateCustomers(v: any): boolean | string {
      return !this.hasCustomerRole || this.model.customers.length > 0 || "Rquired.";
    },
    async save(): Promise<void> {
      this.errorText = null;
      const isValid = await (this.$refs.frmUser as any).validate();
      if (isValid.valid) {
        try {
          this.loading = true;
          const userInfo = await UserService.createUser(this.model);
          this.model = { role: "", customers: [], firstName: "", lastName: "", email: "", password: "" };
          (this.$refs.frmUser as any).reset();
          this.$emit("saved", JSON.stringify(this.model));
        } catch (ex: any) {
          this.showError = true;
          this.errorText = ex.response.data.message;
        } finally {
          this.loading = false;
        }
      }
    },
    cancel(): void {
      this.model = { role: "", customers: [], firstName: "", lastName: "", email: "", password: "" };
      this.$emit("cancel");
    },
  },
});
</script>
