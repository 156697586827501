<template>
  <v-card>
    <v-card-title> {{ model && model.id ? "Edit" : "Add" }} Client </v-card-title>
    <v-card-text>
      <v-form ref="frmRegister" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required]" outlined label="First Name" v-model="model.FirstName" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required]" outlined label="Last Name" v-model="model.LastName" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required, rules.email]" outlined label="Email" v-model="model.Email" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <SelectField
              :rules="[rules.required]"
              :items="customers"
              item-title="text"
              item-value="id"
              v-model="model.AppliedCustomerId"
              label="Customer"
              dense
              outlined
            />
          </v-col>
          <v-col md="6" sm="6" class="12 pt-0 pb-0">
            <v-switch class="mt-1" v-model="model.IsActive" label="Is Active"></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn class="primary mr-10" @click="save" :loading="loading"><v-icon>mdi-check</v-icon> Save</v-btn>
        <v-btn class="secondary" @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import ClientService from "../../services/ClientService";
import ClientModel from "shared-components/src/models/ClientModel";
import CustomerService from "shared-components/src/services/CustomerService";

export default defineComponent({
  props: ["selectedManager"],
  data() {
    return {
      valid: true,
      model: {
        id: "",
        FirstName: "",
        LastName: "",
        Email: "",
        IsActive: false,
        AppliedCustomerId: "",
        AppliedCustomerName: "",
      },
      pageLoading: true,
      loading: false,
      customers: [] as Array<{ id: string; text: string }>,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        email: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
      },
    };
  },
  async mounted() {
    await this.populateCustomers();
    if (this.selectedManager && this.selectedManager.id) {
      this.model.id = this.selectedManager.id;
      this.model.FirstName = this.selectedManager.FirstName;
      this.model.LastName = this.selectedManager.LastName;
      this.model.Email = this.selectedManager.Email;
      this.model.IsActive = this.selectedManager.IsActive;
      this.model.AppliedCustomerId = this.selectedManager.AppliedCustomerId;
      this.model.AppliedCustomerName = this.selectedManager.AppliedCustomerName;
    }
  },
  methods: {
    cancel(): void {
      this.$emit("cancel");
    },
    async populateCustomers() {
      this.customers = (await CustomerService.getList())
        .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
        .map((item: any) => {
          return { id: item.id, text: `${item.FirstName} ${item.LastName}` };
        });
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmRegister as any).validate();
      if (isValid.valid) {
        this.loading = true;
        const item = { ...this.model } as ClientModel;
        if (item.id) {
          await ClientService.update(item);
        } else {
          const savedPortfolioManagerId = await ClientService.set(item);
          item.id = savedPortfolioManagerId;
        }
        this.loading = false;
        this.$emit("saved", item);
      }
    },
  },
});
</script>
