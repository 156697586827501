<template>
  <access-list @notify="sendNotification" showError="showError"></access-list>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AccessList from "shared-components/src/components/Access/AccessList.vue";
import Access from "shared-components/src/models/Access";
import ApiService from "shared-components/src/services/ApiService";
import UserInfo from "shared-components/src/models/UserInfo";
import store from "@/store";

export default defineComponent({
  components: {
    AccessList,
  },
  methods: {
    showError(msg: string): void {
      store.dispatch("showErrorMessage", msg)
    },
    async sendNotification(items: Access[]): Promise<void> {
      items.forEach(async (item) => {
        await ApiService.get(`/access/sendNotif/${this.userInfo.userName}/${item.id}`);
      });
    },
  },
  computed: {
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
  },
});
</script>
