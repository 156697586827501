<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-card>
            <v-card-title class="title">
              <span class="headline">Task List Template</span>
              <v-btn color="primary" @click="openNewTaskDialog">
                <v-icon left>mdi-plus</v-icon>
                New Task Template
              </v-btn>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="9" xs="12">
                  <AutoCompleteField
                    v-model="taskListFilter"
                    :items="taskList"
                    item-title="Title"
                    item-value="id"
                    label="Task"
                    dense
                    outlined
                    placeholder="Task"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
                <v-col class="mt-8" cols="12" md="6" xs="12" sm="3">
                  <v-switch v-model="statusFilter" label="Status" color="primary"></v-switch>
                </v-col>
              </v-row>

              <v-col cols="12" class="filter">
                <v-btn @click="clearFilter">
                  <v-icon left>mdi-close</v-icon>
                  clear Filter
                </v-btn>
                <v-btn color="primary" @click="filterTaskTemplate">
                  <v-icon left>mdi-check</v-icon>
                  Filter
                </v-btn>
              </v-col>
            </v-container>
            <v-data-table :headers="headerTask" :items="tableTasks" class="elevation-1 pr-5 pl-5" :items-per-page="10">
              <template v-slot:item.Status="{ item }">
                {{ getStatusLabel(item.Status) }}
              </template>

              <template v-slot:item.Description="{ item }">
                <v-tooltip location="right">
                  <template v-slot:activator="{ props }">
                    <span v-bind="props">{{ truncate(item.Description) }}</span>
                  </template>
                  <span>{{ item.Description }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-menu offset-y left attach>
                  <template v-slot:activator="{ props }">
                    <v-btn x-small icon color="black" v-bind="props" variant="text">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list density="compact">
                    <v-list-item v-for="(actionItem, index) in dynamicActions(item)" :key="index" @click="actionItem.action(item)" color="primary">
                      <template v-slot:prepend>
                        <v-icon :icon="actionItem.icon" class="mr-1" color="blue" small></v-icon>
                      </template>
                      <v-list-item-title v-text="actionItem.text" class="text-left"></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="taskTemplateDialog" v-if="taskTemplateDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <NewTaskTemplate :editingTask="editingTask" @TaskSaved="addTaskToTable" @close-task-dialog="closeNewTaskDialog"></NewTaskTemplate>
      </v-dialog>
    </v-container>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TaskDetail from "./TaskDetail.vue";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import { TaskTemplateApi, TaskTemplateModel, PaginationRequestModel } from "shared-components/src/services/openApi/api";
import NewTaskTemplate from "./NewTaskTemplate.vue";
import store from "@/store";

const taskTemplateApi = new TaskTemplateApi();
export default defineComponent({
  components: { TaskDetail, TextField, NewTaskTemplate },
  data() {
    return {
      taskList: [] as TaskTemplateModel[],
      taskListFilter: "",
      statusFilter: false,
      headerTask: [
        { title: "Task", align: "start", value: "Name", sortable: false },
        { title: "Description", align: "right", value: "Description", sortable: false },
        { title: "Status", align: "right", value: "Status", sortable: false },
        { title: "Actions", align: "right", value: "actions", sortable: false },
      ],
      actions: [
        { text: "Edit", icon: "mdi-pencil", action: this.editTask },
        { text: "Archive", icon: "mdi-delete", action: this.changeStatus },
      ],
      taskDialog: false,
      taskTemplateDialog: false,
      tableTasks: [] as TaskTemplateModel[],
      selectedTask: null as TaskTemplateModel | null,
      editingTask: {} as TaskTemplateModel | null,
      tableOptions: {} as any,
      sortOrder: "desc",
      sortProp: "Name",
    };
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0];
        }
        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "Name";
        }
      }
    },
  },
  mounted() {
    this.getTabelTaskData();
    this.getTaskTemplateSummery();
  },
  computed: {
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
    dynamicActions() {
      return (item: TaskTemplateModel) => [
        { text: "Edit", icon: "mdi-pencil", action: this.editTask },
        { text: item.Status ? "Archive" : "Active", icon: "mdi-delete", action: this.changeStatus },
      ];
    },
  },
  methods: {
    truncate(item: any) {
      if (item && item.length > 20) {
        return item.substring(0, 20) + "...";
      } else {
        return item;
      }
    },
    getStatusLabel(Status: boolean): string {
      switch (Status) {
        case false:
          return "Archive";
        case true:
          return "Active";
        default:
          return "Unknown";
      }
    },
    closeNewTaskDialog() {
      this.taskTemplateDialog = false;
    },
    openNewTaskDialog() {
      this.taskTemplateDialog = true;
      this.editingTask = null;
    },
    // addTaskToTable(newTask: TaskTemplateModel) {
    //   this.tableTasks.unshift(newTask);
    //   this.tableTasks = [...this.tableTasks];
    // },
    addTaskToTable(newTask: TaskTemplateModel) {
      const existingTaskIndex = this.tableTasks.findIndex((task) => task.Id === newTask.Id);

      if (existingTaskIndex !== -1) {
        this.tableTasks[existingTaskIndex] = newTask;
      } else {
        this.tableTasks.unshift(newTask);
      }
      this.tableTasks = [...this.tableTasks];
    },
    async getTabelTaskData() {
      try {
        const response = await taskTemplateApi.geTaskTemplateList();
        this.tableTasks = response.data;
        console.log(response.data);
      } catch (error) {
        store.dispatch("showErrorMassage", "There is something wrong in getting data!");
      }
    },
    editTask(item: TaskTemplateModel) {
      this.selectedTask = item;
      this.editingTask = { ...item };
      this.taskTemplateDialog = true;
    },
    async changeStatus(item: TaskTemplateModel) {
      try {
        const result = await taskTemplateApi.changeTaskTemplateStatus(item.Id!);

        let updatingStatus = "";
        if (result.data === true) {
          updatingStatus = "Archive";
        } else if (result.data === false) {
          updatingStatus = "Active";
        }

        const taskIndex = this.tableTasks.findIndex((i) => i.Id === item.Id);
        if (taskIndex !== -1) {
          this.tableTasks[taskIndex].Status = updatingStatus === "Archive";
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getTaskTemplateSummery() {
      await taskTemplateApi.geTaskTemplateListSummary().then((data) => {
        this.taskList = data.data;
      });
    },
    async filterTaskTemplate() {
      try {
        const { page, itemsPerPage } = this.tableOptions;

        const filterTaskTemplateModel = {
          pageNumber: page,
          PageSize: itemsPerPage,
          sortOrder: this.sortOrder,
          sortProp: this.sortProp,
          Filters: {
            Name: this.taskListFilter,
            Status: this.statusFilter,
          },
        } as PaginationRequestModel;
        console.log(filterTaskTemplateModel);

        const result = await taskTemplateApi.geTaskTemplateList(filterTaskTemplateModel);
        this.tableTasks = result.data;
      } catch (err) {
        console.error(err);
      }
    },
    clearFilter() {
      this.statusFilter = false;
      this.taskListFilter = "";
    },
  },
});
</script>
<style scoped>
.headline {
  font-weight: bold;
  padding-left: 8px;
}
.title {
  display: flex;
  justify-content: space-between;
}
.filter {
  gap: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
