<template>
  <div class="week-parent">
    <span class="sum-hour">{{ removeDecimals(Week.Hours) }}</span>
    <div>
      <table class="week-days-table" cellspacing="0">
        <thead>
          <tr>
            <th v-for="(i, index) in Week.Days" :key="index + 100">{{ i.DayOfWeek }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(i, index) in Week.Days" :key="index + 1000" class="time-span">{{ i.TimesheetHuors }}</td>
          </tr>
          <tr>
            <td v-for="(i, index) in Week.Days" :key="index + 10000" class="time-span">
              <span v-if="i.TimesheetHuors">
                <v-icon v-if="i.IsLocked" color="success" x-small>mdi-lock</v-icon>
                <v-icon v-else color="error" x-small>mdi-lock-open</v-icon></span
              ><span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td v-for="(i, index) in Week.Days" :key="index + 100000" class="time-span adjustment">{{ i.AdjustmentHours }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";

export default defineComponent({
  props: ["Week"],
  methods: {
    decimalCount(num: any) {
      const numStr = String(num);
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      return 0;
    },
    removeDecimals(value: any) {
      if (value !== 0 && this.decimalCount(value) > 2) {
        return Number(value.toFixed(2));
      }
      return value;
    },
  },
});
</script>
<style lang="scss" scoped>
.week-parent {
  display: flex;
  place-items: center;
  gap: 10px;
  justify-content: center;

  .sum-hour {
    font-size: 14px;
    font-weight: 600;
    min-width: 36px;
    text-align: left;
    width: 100%;
  }

  .adjustment {
    color: red;
  }

  .time-span {
    margin: 0 5px;
  }

  .week-days-table {
    margin: 5px 0;

    th {
      font-size: 11px;
    }

    td {
      font-weight: 600;
    }

    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      padding: 1px 2px;
      border-color: #888;
      max-width: 28px;
      min-width: 28px;
      font-size: 11px;
      font-weight: 600;
    }
  }
}
</style>
