<template>
  <v-card>
    <div v-show="!pageLoading">
      <v-card-title>
        <v-row no-gutters>
          <span> Add Notification</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-spacer></v-spacer>

      <v-card-text>
        <v-form ref="frmNotification" v-model="valid" lazy-validation class="form-inputs">
          <v-row class="align-center">
            <v-col cols="12" md="3">
              <AutoCompleteField
                v-model="selectedUsers"
                :loading="tmLoading"
                :disabled="tmLoading"
                :items="tempUsers"
                @change="changed()"
                :item-title="(item) => generateUserLabel(item)"
                item-value="id"
                outlined
                multiple
                chips
                clearable
                dense
                deletable-chips
                :rules="[rules.required]"
                label="Users"
              />
            </v-col>
            <v-col cols="12" md="1">
              <v-checkbox hide-details label="Active Users" v-model="hasActiveUsers" />
            </v-col>
            <v-col cols="12" md="3">
              <DateInputField label="Schedule" first-day-of-week="1" v-model="model.Schedule" hide-actions placeholder="Date" prepend-icon="" />
            </v-col>
            <v-col cols="12" md="1">
              <v-checkbox hide-details dense label="Send Now" v-model="model.SendNow" />
            </v-col>
            <v-col cols="12" md="2">
              <SelectField v-model="selectedType" :items="types" outlined dense :rules="[rules.required]" label="Type" />
            </v-col>
            <v-col cols="12" md="2">
              <SelectField v-model="selectedSection" :items="sections" outlined dense :rules="[rules.required]" label="Section" />
            </v-col>
            <v-col v-if="selectedSection === 'Other'" cols="12" md="3">
              <TextField
                v-if="model.Url.trim()"
                :maxlength="21"
                :rules="[rules.required]"
                dense
                v-model="customSection"
                label="Other Section"
                outlined
              />
              <TextField v-else dense :maxlength="21" v-model="customSection" label="Other Section" outlined />
            </v-col>
            <v-col v-if="selectedSection === 'Other'" cols="12" md="9">
              <TextField v-if="customSection.trim()" :rules="[rules.required]" dense v-model="model.Url" label="Section Url" outlined> </TextField>
              <TextField v-else dense v-model="model.Url" label="Section Url" outlined> </TextField>
            </v-col>

            <v-col cols="12" md="6">
              <TextField dense label="Push Text" :maxlength="150" v-model="model.PushText" outlined></TextField>
            </v-col>
            <v-col cols="12" md="6">
              <TextField :rules="[rules.required]" :maxlength="150" dense label="Short Description" v-model="model.ShortDesc" outlined></TextField>
            </v-col>
            <v-col cols="12" md="12">
              <TextAreaField :rules="[rules.required]" dense label="Text " v-model="model.Text" outlined></TextAreaField>
            </v-col>
            <v-col cols="12" md="12">
              <TextAreaField dense label="Sms Text" v-model="model.SmsText" outlined></TextAreaField>
            </v-col>
            <v-col cols="12" md="12">
              <TextAreaField v-model="model.EmailText" label="Email Text" outlined></TextAreaField>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col md="12">
          <v-btn color="primary" class="mr-5" variant="elevated" @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
          <v-btn class="secondary_btn" variant="elevated" @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
        </v-col>
      </v-card-actions>
    </div>
    <loading-component v-if="pageLoading"></loading-component>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import CommitmentService from "shared-components/src/services/CommitmentService";
import Commitment from "shared-components/src/models/Commitment";
import Batch from "shared-components/src/models/Batch";
import { NotificationType, NotificationSection } from "shared-components/src/definitions/constants";
import Utils from "shared-components/src/utils/Utils";
import { UserModel, UserOfNotification } from "shared-components/src/services/openApi";

export default defineComponent({
  props: ["users"],
  components: {
    LoadingComponent,
  },
  data() {
    return {
      valid: false,
      pageLoading: false,
      types: Object.values(NotificationType),
      sections: Object.values(NotificationSection),
      sendDatePickerMenu: false,
      model: {
        PushText: "",
        Text: "",
        ShortDesc: "",
        CreationDate: {} as Date,
        SmsText: "",
        EmailText: "",
        Users: [] as UserOfNotification[],
        Schedule: null as Date | null,
        Type: "",
        Section: "",
        SendNow: false,
        Seen: false,
        Status: "",
        Url: "",
      },
      customSection: "",
      tmLoading: false,
      hasActiveUsers: false,
      tempUsers: [] as UserModel[],
      batches: [] as Batch[],
      selectedUsers: [] as UserModel[],
      selectedBatch: "",
      selectedType: "",
      commitments: [] as Commitment[],
      selectedSection: "",
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
      },
    };
  },
  async mounted() {
    this.tmLoading = true;
    this.tempUsers = this.users;
    await this.fetchActiveCommitments();
    this.tmLoading = false;
  },
  methods: {
    async fetchActiveCommitments() {
      var allCommitments = await CommitmentService.getList();
      const theDate = new Date();
      theDate.setMonth(theDate.getMonth() - 2);
      this.commitments = allCommitments.filter((c: any) => c.EndDate && c.EndDate >= theDate);
    },
    isActiveTeammember(teammember: any) {
      return this.commitments.some((c: any) => c.TeamMemberIds && c.TeamMemberIds.includes(teammember.Id));
    },
    changed() {
      if (this.selectedUsers.some((c) => c.id === "")) {
        this.tempUsers = this.tempUsers.filter((c: any) => !c.id);
        this.selectedUsers = this.tempUsers;
      } else {
        if (this.hasActiveUsers) {
          this.tempUsers = this.users.filter(
            (c: any) => (c.name && c.name.trim() && c.surname && c.surname.trim() && this.isActiveTeammember(c)) || c.fullName === "All"
          );
        } else {
          this.tempUsers = this.users;
        }
      }
    },
    generateUserLabel(userModel: UserModel) {
      if (userModel.fullName == "All") {
        return userModel.fullName;
      } else {
        return `${userModel.fullName} - (${userModel.role})`;
      }
    },
    async save() {
      const isValid = await (this.$refs.frmNotification as any).validate();
      if (isValid.valid) {
        var notifStatus = "Pending";

        const allTm = this.selectedUsers.some((st) => !st.id && st.fullName === "All");
        if (allTm) {
          this.selectedUsers = this.users;
        }
        if (this.selectedType) {
          this.model.Type = this.selectedType;
        }
        if (this.selectedSection && this.selectedSection !== "Other") {
          this.model.Section = this.selectedSection;
        } else if (this.selectedSection === "Other") {
          this.model.Section = this.customSection;
        }
        this.model.Section = this.model.Section.trim();
        if (this.selectedUsers) {
          this.selectedUsers.forEach((c) => {
            this.model.Users.push({ UserId: c, Status: notifStatus } as UserOfNotification);
          });
        }
        this.model.CreationDate = new Date();
        this.model.Status = notifStatus;
        await this.$emit("saved", this.model);
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  watch: {
    hasActiveUsers(newVal) {
      if (newVal) {
        this.tempUsers = this.tempUsers.filter(
          (c) => (c.name && c.name.trim() && c.surname && c.surname.trim() && this.isActiveTeammember(c)) || c.fullName === "All"
        );
      } else {
        this.tempUsers = this.users;
      }
    },

    selectedSection(newVal) {
      if (newVal !== "Other") {
        this.model.Url = "";
        this.customSection = "";
      }
    },
    "model.SendNow"(val) {
      if (val) {
        this.model.Schedule = null;
      }
    },
    users(val) {
      this.tempUsers = val;
    },
  },
  computed: {
    selectedSendDate: {
      get(): string {
        return this.model.Schedule ? Utils.toVsDateFormat(this.model.Schedule) : "";
      },
      set(date: string | null): void {
        this.model.Schedule = Utils.vsDateToDatetime(date || "");
      },
    },
    minDate(): string {
      const dateLimit = new Date();
      dateLimit.setDate(dateLimit.getDate() + 1);
      dateLimit.setHours(0);
      dateLimit.setMinutes(0);
      dateLimit.setSeconds(0);
      dateLimit.setMilliseconds(0);
      return Utils.toVsDateFormat(dateLimit);
    },
  },
});
</script>
