import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" New Maintenance Notification")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmRegisterNotif",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        modelValue: _ctx.selectedTeammembers,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTeammembers) = $event)),
                        items: _ctx.teammemberList,
                        "item-title": "FullName",
                        "item-value": "UserAccountsId",
                        multiple: "",
                        chips: "",
                        outlined: "",
                        dense: "",
                        label: "Team Member"
                      }, null, 8, ["modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.Text,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.Text) = $event)),
                        showRequired: "",
                        outlined: "",
                        label: "Text",
                        placeholder: "Notification text"
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "secondary",
                class: "mr-10",
                onClick: _ctx.save,
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("mdi-check")
                    ])),
                    _: 1
                  }),
                  _cache[5] || (_cache[5] = _createTextVNode(" Save"))
                ]),
                _: 1
              }, 8, ["onClick", "loading"]),
              _createVNode(_component_v_btn, {
                color: "primary",
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" cancel")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}