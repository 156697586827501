import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import store from "../store";
import UserService from "../services/UserService";
import { defaultUser } from "@/store/state";
import AppHelper from "shared-components/src/utils/AppHelper";

function getRedirectLocation(to: any) {
  let newLocation;
  if (to.meta && to.meta.authorize && !store.getters.userIsAdmin) {
    newLocation = `/login?returnUrl=${to.path}&r=1`;
  }
  return newLocation;
}

function changeDocumentTitle(to: any) {
  if (typeof document !== "undefined") {
    document.title = to.meta && to.meta.title ? `${to.meta.title}` : `Project Portal`;
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("showLoading");
  if (AppHelper.IsAzureEnv()) {
    const idToken = localStorage.getItem("idToken");
    const needAuth = to && to.meta && (to.meta.frontAuthRequired || to.meta.authorize);
    if (to && to.meta && to.meta.title === "Logout") {
      await store.dispatch("setUserInfo", { ...defaultUser, vm: null });
    }
    if ((!store.state.userInfo || !store.state.userInfo.id) && idToken && needAuth) {
      await UserService.loginWithToken();
    }
    if (!needAuth && store.getters.userIsAdmin) {
      next({ name: "First" });
    }

    if (to && to.meta && to.meta.roles && !(to.meta.roles as string[]).includes(store.state.userInfo.roles)) {
      next({ name: "First" });
    }
    changeDocumentTitle(to);
    let newLocation;
    newLocation = getRedirectLocation(to);
    newLocation ? next(newLocation) : next();
  } else {
    setTimeout(async () => {
      if (to && to.meta && to.meta.frontAuthRequired && !store.state.hasFirebaseAuth) {
        store.state.hasFirebaseAuth = true;
        await UserService.loginWithToken();
      }
      changeDocumentTitle(to);
      let newLocation;
      newLocation = getRedirectLocation(to);
      newLocation ? next(newLocation) : next();
    }, 500);
  }
});

router.afterEach(() => {
  setTimeout(() => {
    store.dispatch("hiddenLoading");
  }, 500);
});

export default router;
