import ApiService from "./ApiService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import BaseCoreSkillService from "shared-components/src/services/CoreSkillService";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";

export default class CoreSkillService extends BaseCoreSkillService {
  public static getList(): Promise<CoreSkill[]> {
    return this._getList(ApiService);
  }

  public static sendAddRequest(skillName: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/coreskill/request/${skillName}`, "")
        .then((result) => {
          if (!result.data || result.data.statusCode != SystemErrors.Authentication.Success || !result.data.value) {
            resolve(false);
          } else {
            resolve(result.data.value);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
