
export default class Utils {
    public static timestampToDate(tms: any): Date {
        return new Date((tms._seconds || tms.seconds) * 1000);
    }

    public static getActivityIcon(activity: string): string {
        let retVal = "mdi-help";
        if (!activity) {
            return retVal;
        }
        switch (activity.toLowerCase()) {
            case "research":
                retVal = "mdi-search"
                break;
            case "design":
                retVal = "mdi-brush";
                break;
            case "code review":
            case "code-review":
                retVal = "mdi-file-find";
                break;
            case "refactoring":
                retVal = "mdi-circle-edit-outline";
                break;
            case "meeting":
                retVal = "mdi-phone";
                break;
            case "testing the code":
            case "testing-the-code":
                retVal = "mdi-replay";
                break;
            case "coding":
                retVal = "mdi-code-braces";
                break;
            case "writing-unit-tests":
            case "writing unit tests":
                retVal = "mdi-test-tube"
                break;
        }
        return retVal;
    }

    public static toVsDateFormat(date: Date): string {
        const y = date.getFullYear().toString();
        let m = (date.getMonth() + 1).toString();
        let d = date.getDate().toString();

        if (m.length < 2) {
            m = "0" + m;
        }
        if (d.length < 2) {
            d = "0" + d;
        }
        return [y, m, d].join("-");
    }
}