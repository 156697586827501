import Customer from "shared-components/src/models/Customer";
import ManagerModel from "shared-components/src/models/ManagerModel";
import ApiService from "./ApiService";
import { OfficeLocationModel } from "shared-components/src/services/openApi/api";

export default class CustomerService {
  public static async getById(id: string): Promise<Customer> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/customer/${id}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as Customer);
          } else {
            const item = result.data as Customer;
            resolve(item);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getList(): Promise<ManagerModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get("/customer", "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              const retVal = { ...item } as ManagerModel;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async set(model: ManagerModel): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post("/customer", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async update(model: ManagerModel): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put("/customer", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static AddOfficeLocation(request: OfficeLocationModel, customerId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/customer/${customerId}/officeLocation`, request)
        .then((result) => {
          if (!result.data) {
            resolve("");
          } else {
            resolve(result.data);
          }
        })
        .catch((customerError) => reject(customerError));
    });
  }

  public static GetOfficeLocations(customerId: string): Promise<OfficeLocationModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/customer/${customerId}/officeLocation`)
        .then((result) => {
          if (!result.data) {
            resolve([] as OfficeLocationModel[]);
          } else {
            resolve(result.data as OfficeLocationModel[]);
          }
        })
        .catch((customerError) => reject(customerError));
    });
  }
}
