<template>
  <v-card>
    <div>
      <v-card-title>
        <v-row no-gutters>
          <span> Rate</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancelRating" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.TeamMemberRate" label="Team Member" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.LineManagerRate" label="Line Manager" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.TDMRate" label="TDM" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.ProjectLeadRate" label="Project Lead" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.PortfolioManagerRate" label="Portfolio Manager" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.HrFinanceRate" label="HR Finance" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.CharityRate" label="Charity" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.OffshoreEntityRate" label="Offshore Entity" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.ToolsRate" label="Tools" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField
              type="number"
              min="0"
              max="10"
              step="0.1"
              v-model="model.FunctionalManagerRate"
              label="Functional Manager"
              outlined
            ></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.BusinessManagerRate" label="Business manager" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.SubsidyRate" label="Subsidy" outlined></TextField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <TextField type="number" min="0" max="10" step="0.1" v-model="model.MiscRate" label="Misc" outlined></TextField>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col md="12">
          <v-btn class="primary mr-5" dark @click="saveRating"><v-icon>mdi-check</v-icon> Save</v-btn>
          <v-btn class="secondary" dark @click="cancelRating"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
        </v-col>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";

export default defineComponent({
  props: ["rateModel"],
  data() {
    return {
      model: {
        TeamMemberRate: 0,
        LineManagerRate: 1,
        TDMRate: 0,
        ProjectLeadRate: 0,
        PortfolioManagerRate: 0,
        HrFinanceRate: 0.5,
        CharityRate: 0,
        OffshoreEntityRate: 0.2,
        ToolsRate: 0.2,
        FunctionalManagerRate: 1,
        BusinessManagerRate: 1,
        SubsidyRate: 0,
        MiscRate: 0,
      },
    };
  },
  methods: {
    cancelRating() {
      this.$emit("cancelRating");
    },
    saveRating() {
      this.$emit("saveRating", this.model);
    },
  },
  mounted() {
    this.model = this.rateModel;
  },
});
</script>
