<template>
  <contribution-report
    ref="cntbnReport"
    :customers="customers"
    :projects="projects"
    :authors="authors"
    :commits="commits"
    :timesheets="timesheets"
    @createReport="createReport"
    @customerSelected="customerSelected"
  ></contribution-report>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import ContributionReport from "shared-components/src/components/Reports/Contribution/ContributionReport.vue";
import Project from "shared-components/src/models/Project";
import Customer from "shared-components/src/models/Customer";
import Repository, { Commit } from "shared-components/src/models/Repository";
import AuthorService from "shared-components/src/services/AuthorService";
import ProjectService from "shared-components/src/services/ProjectService";
import RepositoryService from "shared-components/src/services/RepositoryService";
import TimesheetService from "shared-components/src/services/TimesheetService";
import ContributionReportService from "shared-components/src/services/ContributionReportService";
import Author from "shared-components/src/models/Author";
import Timesheet from "shared-components/src/models/Timesheet";
import Commitment from "shared-components/src/models/Commitment";

export default defineComponent({
  components: {
    ContributionReport,
  },
  data() {
    return {
      customers: [] as Array<{ id: string; text: string }>,
      projects: [] as Project[],
      repositories: [] as Repository[],
      authors: [] as Author[],
      commits: [] as Commit[],
      timesheets: [] as Timesheet[],
      allCommitments: [] as Commitment[],
      allAuthors: [] as Author[],
    };
  },
  methods: {
    async customerSelected($customerId: string) {
      if ($customerId) {
        this.projects = await ProjectService.getByCustomerId($customerId);
      }
    },
    async createReport($model: { customerId: string; projectIds: string[]; dateRange: Date[] }) {
      this.repositories = [];
      this.authors = [];
      this.commits = [];
      const teammemberIds = [] as string[];
      const commitments = this.allCommitments.filter((cmtmnt) => {
        let retVal = $model.projectIds.findIndex((prjId) => cmtmnt.ProjectIds.indexOf(prjId) > -1) > -1;
        if (retVal) {
          if (!cmtmnt.StartDate || cmtmnt.StartDate > $model.dateRange[1] || (cmtmnt.EndDate || $model.dateRange[0]) < $model.dateRange[0]) {
            retVal = false;
          } else {
            retVal = true;
          }
        }
        return cmtmnt;
      });
      commitments.map((cmtmn) => cmtmn.TeamMemberIds).forEach((ids) => teammemberIds.push(...ids));
      if (teammemberIds && teammemberIds.length > 0) {
        const tmTimesheets = await TimesheetService.getListInDateRange("", $model.dateRange[0], $model.dateRange[1]);
        this.timesheets = tmTimesheets;
        if (tmTimesheets && tmTimesheets.length > 0) {
          this.timesheets = tmTimesheets.filter(
            (ts: any) => ts.TeamMemberId && teammemberIds.includes(ts.TeamMemberId) && commitments.findIndex((c) => c.id === ts.CommitmentId) > -1
          );
        }
      }

      if ($model.projectIds && $model.projectIds.length > 0) {
        const projects = this.projects.filter((prj) => $model.projectIds.indexOf(prj.id) > -1);
        if (projects && projects.length > 0) {
          const repositoryHttpsLinks = [] as string[];
          projects.forEach((p) => {
            if (p.Repositories && p.Repositories.length > 0) {
              p.Repositories.forEach((httpsLink: any) => {
                if (repositoryHttpsLinks.indexOf(httpsLink) === -1) {
                  repositoryHttpsLinks.push(httpsLink);
                }
              });
            }
          });
          if (repositoryHttpsLinks && repositoryHttpsLinks.length > 0) {
            const allRepos = await RepositoryService.getList();
            if (allRepos && allRepos.length > 0) {
              this.repositories = allRepos.filter((c: any) => repositoryHttpsLinks.includes(c.HttpsLink));
            }
            for (const r of this.repositories) {
              await RepositoryService.getCommits(r, $model.dateRange);
              if (!r.Commits) {
                continue;
              }
              for (const c of r.Commits) {
                if (
                  c.author &&
                  this.authors.findIndex((author) => AuthorService.extractEmail(author.Author) === AuthorService.extractEmail(c.author)) === -1
                ) {
                  const author = this.allAuthors.find((au) => AuthorService.extractEmail(au.Author) === AuthorService.extractEmail(c.author));
                  if (author) {
                    this.authors.push(author);
                  }
                }

                this.commits.push(c);
              }
            }
          }
        }
      }
      (this.$refs.cntbnReport as any).showList();
    },
  },
  async mounted() {
    var datas = await ContributionReportService.getDatas();
    if (datas) {
      this.customers = datas.Customers.filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim())).map(
        (item: any) => {
          return { id: item.id, text: `${item.FirstName} ${item.LastName}` };
        }
      );
      this.allCommitments = datas.Commitments;
      this.allAuthors = datas.Authors;
    }
  },
});
</script>
