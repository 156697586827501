<template>
 <div>
     <holiday-list></holiday-list>
 </div>
</template>

<script lang="ts">
import HolidayList from "./HolidayList.vue";

import Vue, { defineComponent } from "vue";

interface RowItem {
  items: string[];
}
export default defineComponent({
  components: {
    HolidayList
  },
});
</script>
