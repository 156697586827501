import PortfolioManager from "shared-components/src/models/PortfolioManager";
import ApiService from "./ApiService";

export default class PortfolioManagerService {
  public static async getById(id: string): Promise<PortfolioManager> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portfolioManagers/${id}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as PortfolioManager);
          } else {
            const item = result.data as PortfolioManager;
            resolve(item);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getList(): Promise<PortfolioManager[]> {
    return new Promise((resolve, reject) => {
      ApiService.get("/portfolioManagers", "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              const retVal = { ...item } as PortfolioManager;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async set(model: PortfolioManager): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post("/portfolioManagers", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async update(model: PortfolioManager): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put("/portfolioManagers", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
