<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="mt-3">Activity List</span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mt-2 mr-3" dark @click="ShowAddActivityPopup"> <v-icon>mdi-plus</v-icon> New Activity </v-btn>
            </v-row>
          </v-card-title>
          <v-card-text class="mt-4">
            <v-data-table class="pt-0" :items="activities" :item-class="row_classes" :loading="Loading" :headers="headers">
              <template v-slot:item.Value="{ item }">
                <strong>{{ item.Activity.Value }} {{ item.Activity.Disabled ? "- Disabled" : "" }}</strong>
              </template>
              <template v-slot:item.IconClass="{ item }">
                <v-icon>{{ item.Activity.IconClass }}</v-icon>
              </template>
              <template v-slot:item.Actions="{ item }">
                <button :disabled="item.Loading" @click="ShowUpdateActivityPopup(item.Activity)" title="Update">
                  <v-icon>mdi-lead-pencil</v-icon>
                </button>
                <button :disabled="item.Loading" @click="SetActivityAsDisabled(item.Activity)">
                  <v-icon v-if="!item.Activity.Disabled" title="Disable" :disabled="item.Loading">mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-if="item.Activity.Disabled" title="Active" :disabled="item.Loading">mdi-checkbox-blank-circle-outline</v-icon>
                </button>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showPopup" persistent max-width="600" @keydown.esc="showPopup = false">
      <add-activity
        @cancel="showPopup = false"
        @save="ActivityAdded"
        :activityModel="selectedActivity"
        :activityList="activities"
        v-if="showPopup"
      ></add-activity>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import { Activity } from "shared-components/src/models/Activity";
import ActivityService from "shared-components/src/services/ActivityService";
import AddActivity from "./AddActivity.vue";

export default defineComponent({
  components: {
    AddActivity,
  },
  data() {
    return {
      Loading: true,
      showPopup: false,
      selectedActivity: null as Activity | null,
      activities: [] as { Loading: boolean; Activity: Activity }[],
      headers: [
        { title: "Name", align: "start", value: "Value", sortable: false, width: "80%" },
        { title: "Icon", value: "IconClass", sortable: false, width: "10%" },
        { title: "Action", value: "Actions", sortable: false },
      ],
    };
  },
  methods: {
    row_classes(item: any) {
      if (item.Activity.Disabled) {
        return "table-disabled-row"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    ShowAddActivityPopup(): void {
      this.selectedActivity = null;
      this.showPopup = true;
    },
    SetActivityAsDisabled(model: Activity): void {
      model.Disabled = !model.Disabled;
      let existItem = this.activities.find((item) => item.Activity.id == model.id);
      if (existItem) {
        this.activities[this.activities.indexOf(existItem)].Loading = true;
        ActivityService.update(model).then((result: any) => {
          if (existItem) {
            this.activities[this.activities.indexOf(existItem)].Loading = false;
            this.activities[this.activities.indexOf(existItem)].Activity.Disabled = model.Disabled;
          }
        });
      }
    },
    ShowUpdateActivityPopup(model: Activity): void {
      this.selectedActivity = model;
      this.showPopup = true;
    },
    ActivityAdded($model: Activity): void {
      this.showPopup = false;
      let itemExist = this.activities.find((item) => item.Activity.id == $model.id);
      if (itemExist) {
        this.activities[this.activities.indexOf(itemExist)].Activity.Value = $model.Value;
        this.activities = this.activities.sort(this.compare);
      } else {
        this.activities.push({ Loading: false, Activity: $model });
        this.activities = this.activities.sort(this.compare);
      }
    },
    compare(a: any, b: any) {
      if (a.Activity.Value < b.Activity.Value) return -1;
      if (a.Activity.Value > b.Activity.Value) return 1;
      return 0;
    },
  },
  computed: {},
  async mounted() {
    this.activities = (await ActivityService.getActivityList())
      .map((item: any) => {
        return {
          Loading: false,
          Activity: item,
        };
      })
      .sort(this.compare);
    this.Loading = false;
  },
});
</script>
<style>
.table-disabled-row {
  background-color: rgb(209 209 209);
  color: #747474;
}
</style>
