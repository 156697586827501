<template>
  <div>
    <repository-list
      :repositories="repositories"
      :pageLoading="pageLoading"
      :customers="customers"
      @fetchRepositories="fetchRepositories"
      @errorRaised="errorRaised"
      :isAdmin="true"
    ></repository-list>
  </div>
</template>

<script lang="ts">
import RepositoryList from "shared-components/src/components/Repository/List.vue";
import CustomerService from "shared-components/src/services/CustomerService";
import RepositoryService from "shared-components/src/services/RepositoryService";
import { TeammemberRepositoryVM } from "shared-components/src/viewModels/response/repository/TeammemberRepositoryVM";
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import { HIDDEN_LOADING, SHOW_LOADING } from "@/store/types";
import ManagerModel from "shared-components/src/models/ManagerModel";
import store from "@/store";

export default defineComponent({
  components: {
    RepositoryList,
  },
  data() {
    return {
      repositories: [] as TeammemberRepositoryVM[],
      customers: [] as ManagerModel[],
      pageLoading: true,
    };
  },
  methods: {
    ...mapMutations([SHOW_LOADING, HIDDEN_LOADING]),
    errorRaised(msg: string): void {
      store.dispatch("showErrorMessage", msg)
    },
    async fetchRepositories() {
      try {
        this.pageLoading = true;
        this.customers = await CustomerService.getList();
        this.repositories = await RepositoryService.getRepositoriesByTeamMember();
        this.pageLoading = false;
      } catch (error) {
        this.pageLoading = false;
      }
    },
  },
});
</script>
