<template>
  <div>
    <v-card v-show="!pageLoading">
      <v-card-title>
        Search Team members
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pr-0">
        <v-divider></v-divider>
        <v-row class="mt-2">
          <v-col class="nameField pr-8" cols="12" md="6" xs="12" sm="6">
            <v-text-field dense outlined class="mt-md-3" label="Teammember" type="text" hide-details v-model="teammemberName" />
          </v-col>
          <v-col cols="12" md="6" xs="12" sm="6" class="pr-6">
            <v-text-field dense outlined class="mt-md-3" label="Job Title" hide-details type="text" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="searchFields pr-8" cols="12" md="6" xs="12" sm="6">
            <v-combobox
              hide-details
              class="mt-md-3 mb-md-8"
              label="Skills"
              :items="skills"
              v-model="selectedSkills"
              item-title="Text"
              item-value="id"
              multiple
              chips
              outlined
              dense
              :loading="skillLoading"
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar class="accent text_white" left v-text="data.item.Text.slice(0, 1).toUpperCase()"></v-avatar>
                  {{ data.item.Text }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" md="6" xs="12" sm="6" class="pr-6">
            <v-text-field hide-details dense outlined class="mt-md-3" label="Total experience" type="text" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table class="pr-5" :headers="headers" :items="tableData" :options.sync="tableOptions">
              <template v-slot:item.JobTitle="{ item }"> {{ item.SpecificTitle }}</template>
              <template v-slot:item.Skills="{ item }">{{ getSkillsNames(item) }}</template>
              <template v-slot:item.TotalExperience="{ item }"> {{ item.ProfessionalDetail?.ExperienceYear }} </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-20" @click="getTeammember(item.Id)">mdi-pencil</v-icon>
                <v-icon small class="mr-20">mdi-account</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog v-if="profileDialog" v-model="profileDialog" fullscreen hide-overlay transition="dialog-bottom-transiton">
          <v-card>
            <div class="close-profile-dialog">
              <v-btn elevation="2" icon @click="profileDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <Information :selectedTeammemberModel="selectedTeammemberModel" :isHiringmanager="isHiringmanager"> </Information>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <loading-component v-if="pageLoading"></loading-component>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import ReviewService from "shared-components/src/services/ReviewService11";
import { Teammember, TeammemberSkill } from "shared-components/src/models/Teammember";
import { Review } from "shared-components/src/models/Review";
import CareerHistories from "shared-components/src/models/CareerHistories";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import Utils from "shared-components/src/utils/Utils";
import NewAssessment from "./NewAssessment.vue";
import NewReview from "./NewReview.vue";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import Assessment from "shared-components/src/models/Assessment";
import { Traits } from "shared-components/src/models/Traits";
import AssessmentService from "shared-components/src/services/AssessmentService";
import CustomerService from "shared-components/src/services/CustomerService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import TraitsService from "shared-components/src/services/TraitsService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CoreSkillService from "@/services/CoreSkillService";
import AppHelper from "shared-components/src/utils/AppHelper";
import Information from "./Information.vue";
import "shared-components/assets/main.scss";
import "shared-components/assets/style/app.scss";
import Details from "shared-components/src/models/Details";
import DetailsService from "shared-components/src/services/DetailsService";
import ApiService from "@/services/ApiService";
import { PaginationRequestModel, TeammemberApi } from "shared-components/src/services/openApi/api";
import store from "@/store";

export interface TeamMemberRate extends Teammember {
  SkillPoint: number | 0;
  IsClicked: boolean | false;
  EnglishSpokenLevel: boolean | false;
  EnglishWritetenLevel: boolean | false;
}

const teammemberApi = new TeammemberApi();

export default defineComponent({
  components: {
    NewAssessment,
    NewReview,
    ConfirmDialog,
    LoadingComponent,
    Information,
  },
  data() {
    return {
      skillLoading: true,
      pageLoading: true,
      skills: [] as CoreSkill[],
      selectedSkills: [] as string[],
      teammemberName: "",
      teammembers: [] as TeamMemberRate[],
      filteredTeammembers: [] as TeamMemberRate[],
      assessments: [] as Assessment[],
      selectedAssessment: null as Assessment | null,
      selectedReview: null as Review | null,
      selectedTeammember: {},
      reviews: [] as Review[],
      traits: [] as Traits[],
      selectedTeammemberModel: {
        CV: "",
        id: "",
        fullName: "",
        nickName: "",
        city: "",
        email: "",
        timeZone: "",
        memberSince: "",
        linkedinAddress: "",
        education: "",
        description: "",
        skills: [] as TeammemberSkill[] | null,
        phone: "",
        englishSpokenLevel: false,
        englishWritetenLevel: false,
        photoUrl: "",
        careerHistories: [] as CareerHistories[] | null,
      },
      confirmModel: {
        title: "",
        text: "",
      },
      careerCollapsed: false,
      customers: [] as Array<{ id: string; Name: string }>,
      experiencedCollapsed: false,
      languageCollapsed: false,
      assessmentCollapsed: false,
      reviewCollapsed: false,
      showNewAssessment: false,
      showNewReview: false,
      headers: [
        { title: "TeamMember", value: "FullName", sortable: false },
        { title: "Job Title", value: "JobTitle", sortable: false },
        { title: "Skills", value: "Skills", sortable: false },
        { title: "Total Experience", value: "TotalExperience", sortable: false },
        { title: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      jobTitles: [] as Details[],
      details: [] as Details[],
      tableData: [] as any,
      profileDialog: false,
      showConfirmAs: false,
      showConfirmRv: false,
      tableOptions: {} as any,
      sortOrder: "desc",
      sortProp: "FullName",
    };
  },
  computed: {
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
  },
  methods: {
    async getTeamMemberData() {
      const { page, itemsPerPage } = this.tableOptions;

      const paginationModel = {
        pageSize: 10,
        pageNumber: 1,
        sortOrder: "desc",
        sortProp: "FullName",
      } as PaginationRequestModel;
      try {
        const response = await teammemberApi.getTeammemberList(paginationModel);
        const listItems = response.data as any[];
        this.teammembers = listItems
          .filter((c) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
          .map((item) => {
            item.FullName = item.FirstName + " " + item.LastName;
            item.SkillPoint = 0;
            if (item.Skills) {
              item.SkillPoint = this.getSkillPoint(item.Skills);
            }
            return item;
          });
        this.teammembers = this.teammembers.sort((a, b) => (a.SkillPoint < b.SkillPoint ? 1 : -1));
        this.filteredTeammembers = this.teammembers;
        this.tableData = listItems.map((item) => ({
          FullName: item.FullName,
          skills: item.Skills,
          Id: item.Id,
          SpecificTitle: item.Title,
          ProfessionalDetail: item.ProfessionalDetail,
        }));
      } catch (error) {
        console.error("Error fetching team member data:", error);
      }
    },
    findSkillName(id: any) {
      return this.getSkillName(id);
    },
    getSkillName(id: any) {
      const item = this.skills.find((c) => c.id === id);
      if (item) {
        return item.Text;
      } else {
        return "";
      }
    },
    getSkillsNames(teamMember: any) {
      const skillNames = [] as string[];
      if (teamMember.skills) {
        teamMember.skills.forEach((element: any) => {
          skillNames.push(this.getSkillName(element.SkillId));
        });
      }

      return skillNames.join(", ");
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails(ApiService);
    },
    // getJobTitleNames(item) {
    //   return this.details.filter((item) => item.Type === "JobTitle").map((item) => item.);
    // },
    async cvDownload() {
      const downloadLink = await TeamMemberService.getResumeDownloadLink(this.selectedTeammemberModel.id);
      window.open(downloadLink, "_blank");
    },
    async getTeammember(id: any) {
      this.filteredTeammembers.forEach((item) => {
        item.IsClicked = false;
      });
      const teammemberItem: any = this.filteredTeammembers.find((obj: any) => obj.Id === id);
      if (teammemberItem) {
        teammemberItem.IsClicked = true;
        this.selectedTeammemberModel.id = teammemberItem.Id;
        this.selectedTeammemberModel.fullName = teammemberItem.FirstName + " " + teammemberItem.LastName;
        this.selectedTeammemberModel.nickName = teammemberItem.NickName ? teammemberItem.NickName : "----";
        this.selectedTeammemberModel.city = teammemberItem.City ? teammemberItem.City : "----";
        this.selectedTeammemberModel.memberSince = teammemberItem.MemberSince ? teammemberItem.MemberSince : "----";
        this.selectedTeammemberModel.linkedinAddress = teammemberItem.LinkedIn ? teammemberItem.LinkedIn : "----";
        this.selectedTeammemberModel.education = teammemberItem.Educations
          ? this.formatArray(teammemberItem.Educations.map((c: any) => c.StudyField))
          : "----";
        this.selectedTeammemberModel.description = teammemberItem.Description ? teammemberItem.Description : "----";
        this.selectedTeammemberModel.email = teammemberItem.Email ? teammemberItem.Email : "----";
        this.selectedTeammemberModel.phone = teammemberItem.Phone ? teammemberItem.Phone : "----";
        this.selectedTeammemberModel.skills = teammemberItem.Skills;
        this.selectedTeammemberModel.englishSpokenLevel = teammemberItem.EnglishSpokenLevel;
        this.selectedTeammemberModel.englishWritetenLevel = teammemberItem.EnglishWritetenLevel;
        this.selectedTeammemberModel.photoUrl = teammemberItem.PhotoUrl ? teammemberItem.PhotoUrl : "";
        this.selectedTeammemberModel.CV = teammemberItem.CV ? teammemberItem.CV : "";
        this.selectedTeammemberModel.timeZone = teammemberItem.Timezone ? teammemberItem.Timezone : "----";
        this.selectedTeammemberModel.careerHistories = teammemberItem.CareerHistories;
        this.careerCollapsed = false;
        this.profileDialog = true;
        this.experiencedCollapsed = false;
        this.languageCollapsed = false;
        await this.fetchReviews();
        await this.fetchAssessments();
      }
      this.selectedTeammember = id;
    },
    async fetchAssessments() {
      this.assessments = await AssessmentService.getByTeammemberId(this.selectedTeammemberModel.id);
    },
    async fetchReviews() {
      if (!AppHelper.IsAzureEnv()) {
        this.reviews = await ReviewService.getList(this.selectedTeammemberModel.id);
      }
    },
    async fetchTraits(): Promise<void> {
      if (!AppHelper.IsAzureEnv()) {
        this.traits = await TraitsService.getList();
      }
    },
    async fetchTeammembers() {
      try {
        var page = {
          pageNumber: 1,
          pageSize: 10,
          sortOrder: "desc",
          sortProp: "FullName",
        } as PaginationRequestModel;
        new TeammemberApi()
          .getTeammemberList(page)
          .then((items: any) => {
            const listItems = items as unknown as TeamMemberRate[];
            this.teammembers = listItems
              .filter((c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
              .map((item: any) => {
                item.FullName = item.FirstName + " " + item.LastName;
                item.SkillPoint = 0;
                if (item.Skills) {
                  item.SkillPoint = this.getSkillPoint(item.Skills);
                }
                return item;
              });
            this.teammembers = this.teammembers.sort((a, b) => (a.SkillPoint < b.SkillPoint ? 1 : -1));
            this.filteredTeammembers = this.teammembers;
            this.tableData = listItems.map((item: any) => ({
              FullName: item.FullName,
              skills: item.Skills,
              Id: item.Id,
              SpecificTitle: item.SpecificTitle,
              ProfessionalDetail: item.ProfessionalDetail,
            }));
          })
          .catch((err: any) => this.$emit("errorRaised", err));
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },

    //       .then((items) => {
    //         const listItems = items as TeamMemberRate[];
    //         this.teammembers = listItems
    //           .filter((c) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
    //           .map((item) => {
    //             item.FullName = item.FirstName + " " + item.LastName;
    //             item.SkillPoint = 0;
    //             if (item.Skills) {
    //               item.SkillPoint = this.getSkillPoint(item.Skills);
    //             }
    //             return item;
    //           });
    //         this.teammembers = this.teammembers.sort((a, b) => (a.SkillPoint < b.SkillPoint ? 1 : -1));
    //         this.filteredTeammembers = this.teammembers;
    //         this.tableData = listItems.map((item) => ({
    //           FullName: item.FullName,
    //           skills: item.Skills,
    //           Id: item.Id,
    //           SpecificTitle: item.SpecificTitle,
    //           ProfessionalDetail: item.ProfessionalDetail,
    //         }));
    //       })
    //       .catch((err) => this.$emit("errorRaised", err));
    //   } catch (ex) {
    //     this.$emit("errorRaised", ex);
    //   }
    // },
    async editAssessment($item: Assessment): Promise<void> {
      this.selectedAssessment = $item;
      this.showNewAssessment = true;
    },

    async editReview($item: Review): Promise<void> {
      this.selectedReview = $item;
      this.showNewReview = true;
    },

    removeAssessment($item: Assessment): void {
      this.confirmModel.title = `Remove Assessment`;
      this.confirmModel.text = "Are you sure?";
      this.showConfirmAs = true;
      this.selectedAssessment = $item;
    },
    async confirmRemoveAssessment(): Promise<void> {
      this.showConfirmAs = false;
      if (this.selectedAssessment) {
        const idx = this.assessments.findIndex((x) => x.id === (this.selectedAssessment ? this.selectedAssessment.id : ""));
        await AssessmentService.remove(this.selectedAssessment.id);
        this.assessments.splice(idx, 1);
        this.selectedAssessment = null;
      }
    },
    removeReview($item: Review): void {
      this.confirmModel.title = `Remove Assessment`;
      this.confirmModel.text = "Are you sure?";
      this.showConfirmRv = true;
      this.selectedReview = $item;
    },
    async confirmRemoveReview(): Promise<void> {
      this.showConfirmRv = false;
      if (this.selectedReview) {
        const idx = this.reviews.findIndex((x) => x.Id === (this.selectedReview ? this.selectedReview.Id : ""));
        if (!AppHelper.IsAzureEnv()) {
          await ReviewService.remove(this.selectedTeammemberModel.id, this.selectedReview.Id);
        }

        this.reviews.splice(idx, 1);
        this.selectedReview = null;
      }
    },
    cancelRemove(): void {
      this.showConfirmAs = false;
      this.showConfirmRv = false;
      this.selectedAssessment = null;
      this.selectedReview = null;
    },
    openNewAssessment() {
      this.selectedAssessment = null;
      this.showNewAssessment = true;
    },
    openNewReview() {
      this.selectedReview = null;
      this.showNewReview = true;
    },

    assessmentSaved($model: Assessment): void {
      const itemIndex = this.assessments.findIndex((x) => x.id === $model.id);
      if (itemIndex > -1) {
        this.assessments.splice(itemIndex, 1, $model);
      } else {
        this.assessments.push($model);
      }
      this.selectedAssessment = null;
      this.showNewAssessment = false;
      this.assessmentCollapsed = false;
    },
    reviewSaved($model: Review): void {
      const itemIndex = this.reviews.findIndex((x) => x.Id === $model.Id);
      if (itemIndex > -1) {
        this.reviews.splice(itemIndex, 1, $model);
      } else {
        this.reviews.push($model);
      }
      this.selectedReview = null;
      this.showNewReview = false;
      this.reviewCollapsed = false;
    },
    getSummaryOfText(item: any) {
      if (this.reviews) {
        if (item && item.length > 15) {
          return item.substring(0, 15) + "...";
        }
        return item;
      }
      return "";
    },
    getCareerHistoryDate(date: any) {
      if (date) {
        let dateTime;
        if (date.seconds) {
          dateTime = Utils.timestampToDate(date);
        } else {
          dateTime = Utils.vsDateToDatetime(date);
        }
        return Utils.toVsDateFormat(dateTime);
      }
    },
    getDate(date: any) {
      if (date) {
        const dateTime = Utils.timestampToDate(date);
        const dateFromat = Utils.toVsDateFormat(dateTime);
        if (!dateFromat.includes("NaN")) {
          return dateFromat;
        } else {
          const convertedDate = Utils.toVsDateFormat(date);
          return convertedDate;
        }
      }
      return "";
    },
    calculateSkillPoint(skill: any) {
      let point = 0;
      if (skill && skill.Level) {
        switch (skill.Level) {
          case "Advanced":
            point += 5;
            break;
          case "Intermediate":
            point += 3;
            break;
          case "Basic":
            point += 1;
          default:
            point += 0;
            break;
        }
      }
      return point;
    },
    getSkillPoint(skills: TeammemberSkill[]) {
      let point = 0;
      if (skills && skills.length > 0) {
        skills.forEach((element: any) => {
          point += this.calculateSkillPoint(element);
        });
      }

      return point;
    },
    getByName() {
      this.filteredTeammembers = this.teammembers.filter((items: any) => items.FullName.toLowerCase().includes(this.teammemberName.toLowerCase()));
      this.teammembers.forEach((item: any) => {
        if (!this.filteredTeammembers.includes(item)) {
          this.filteredTeammembers.push(item);
        }
      });
    },
    formatArray(items: any) {
      if (items && items.length > 0) {
        return items.join(", ");
      } else {
        return "";
      }
    },
    formatPlatform(items: any) {
      return items.join(", ");
    },
    getTraits(weak: any) {
      if (weak) {
        return weak.Name;
      }
      return "";
    },
    getBySkill() {
      const findTeammember = [] as TeamMemberRate[];
      this.teammembers.forEach((item: any) => {
        if (item.Skills && item.Skills.length > 0) {
          const itemSkills = item.Skills.filter((skill: any) => skill.SkillId).map((skill: any) => skill.SkillId) as string[];
          let find = false;
          this.selectedSkills.forEach((sk: any) => {
            find = itemSkills.includes(sk);
          });
          if (find) {
            item.SkillPoint = 0;
            this.selectedSkills.forEach((sk: any) => {
              if (item.Skills) {
                const selectedSkillObject = item.Skills.find((is: any) => is.SkillId === sk);
                item.SkillPoint += this.calculateSkillPoint(selectedSkillObject);
              }
            });
            findTeammember.push(item);
          }
        }
      });
      this.filteredTeammembers = findTeammember.sort((a: any, b: any) => (a.SkillPoint < b.SkillPoint ? 1 : -1));
      let sortedTeammembers = [] as TeamMemberRate[];
      this.teammembers.forEach((item: any) => {
        if (!this.filteredTeammembers.includes(item)) {
          if (item.Skills) {
            item.SkillPoint = this.getSkillPoint(item.Skills);
          }
          sortedTeammembers.push(item);
        }
      });
      sortedTeammembers = sortedTeammembers.sort((a, b) => (a.SkillPoint < b.SkillPoint ? 1 : -1));
      sortedTeammembers.forEach((st) => {
        this.filteredTeammembers.push(st);
      });
    },
    async fetchCustomers(): Promise<void> {
      this.customers = (await CustomerService.getList())
        .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },
    customSort(items: any, index: any, isDesc: any) {
      items.sort((a: any, b: any) => {
        if (index[0] === "AssessmentDate") {
          if (!isDesc[0]) {
            return new Date(a[index]).getTime() - new Date(b[index]).getTime();
          } else {
            return new Date(b[index]).getTime() - new Date(a[index]).getTime();
          }
        }
      });
      return items;
    },
  },
  watch: {
    tableOptions(newVal: any, oldValue: any) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0];
        }

        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "asc";
        }
      }
    },
    teammemberName(newVal: any) {
      if (newVal.trim()) {
        this.getByName();
      } else {
        if (this.selectedSkills.length > 0) {
          this.getBySkill();
        } else {
          this.teammembers.forEach((item: any) => {
            if (item.Skills) {
              item.SkillPoint = this.getSkillPoint(item.Skills);
            }
          });
          this.filteredTeammembers = this.teammembers.sort((a, b) => (a.SkillPoint < b.SkillPoint ? 1 : -1));
        }
      }
    },
    selectedSkills(newVal: any) {
      if (newVal && newVal.length > 0) {
        this.getBySkill();
      } else {
        if (this.teammemberName.trim()) {
          this.getByName();
        } else {
          this.teammembers.forEach((item: any) => {
            if (item.Skills) {
              item.SkillPoint = this.getSkillPoint(item.Skills);
            }
          });
          this.filteredTeammembers = this.teammembers.sort((a, b) => (a.SkillPoint < b.SkillPoint ? 1 : -1));
        }
      }
    },
  },
  async mounted() {
    await this.getTeamMemberData();
    await this.loadDetailsList();
    // await this.fetchTeammembers();
    await this.fetchCustomers();
    await this.fetchTraits();
    this.skills = await CoreSkillService.getList();
    this.skillLoading = false;
    this.pageLoading = false;
  },
});
</script>
<style scoped>
.actionButs {
  padding-left: 2px;
  padding-right: 2px;
}

.assessmentBut {
  float: left !important;
}

.teammemberMenu {
  height: 100vh;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid black;
  margin-left: 12px;
  margin-bottom: 10px;
}

.teammemberMenu h2 {
  text-align: center;
  border-bottom: 1px solid #dadada;
  height: 37px;
}

.teammemberList ul {
  padding-left: 0px;
}

.teammemberList ul li {
  height: 40px;
  list-style-type: none;
  line-height: 40px;
}

.teammemberList ul li a {
  padding-left: 20px;
  display: block;
  color: black;
}

.teammemberList ul li a:hover {
  background: rgb(226 226 226);
}

.teammemberDetail {
  margin-left: 12px;
  padding-top: 0px;
}

.teammemberDetail div {
  padding-bottom: 0px;
  max-width: 100%;
}

.teammemberDetail div p {
  margin-bottom: 5px;
}

.searchFields {
  padding-bottom: 0px;
}

.searchFields div {
  margin-bottom: 0px !important;
}

.fullHeight {
  margin-bottom: 0px !important;
}

.nameField {
  padding-bottom: 0px !important;
}

.profile-image {
  width: 120px;
  height: 120px;
  border: 3px solid gray;
  border-radius: 6px;
}

.addAssessmentBut {
  direction: rtl;
}

.close-profile-dialog {
  padding-left: 15px;
  padding-top: 10px;
}

@media screen and (max-width: 539px) {
  .assessmentBut {
    width: 100%;
  }
}
</style>
