import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_CreateOrEditVM = _resolveComponent("CreateOrEditVM")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_MaintenanceNotification = _resolveComponent("MaintenanceNotification")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[10] || (_cache[10] = _createTextVNode(" VM List ")),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "primary",
                class: "mr-4",
                dark: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMaintenancePopup()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("mdi-bell-outline")
                    ])),
                    _: 1
                  }),
                  _cache[7] || (_cache[7] = _createTextVNode(" New Maintenance Notification"))
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                color: "primary",
                dark: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("mdi-plus")
                    ])),
                    _: 1
                  }),
                  _cache[9] || (_cache[9] = _createTextVNode(" Register new VM"))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmList",
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "secondary",
                onClick: _ctx.removeSelection
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("mdi-tag-remove-outline")
                    ])),
                    _: 1
                  }),
                  _cache[12] || (_cache[12] = _createTextVNode(" Deselect all"))
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_data_table, {
                ref: "myList",
                items: _ctx.vmList,
                headers: _ctx.headers,
                loading: _ctx.loading,
                "item-key": "Id",
                "onClick:row": _ctx.selectRow
              }, {
                "item.IsSelected": _withCtx(({ item }) => [
                  _createVNode(_component_v_checkbox, {
                    class: "vm-checkbox",
                    modelValue: item.IsSelected,
                    "onUpdate:modelValue": ($event: any) => ((item.IsSelected) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                "item.Teammember": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(_ctx.getTMName(item.OwnerId)), 1)
                ]),
                "item.action": _withCtx(({ item }) => [
                  _createVNode(_component_v_menu, {
                    "offset-y": "",
                    location: "bottom",
                    "scroll-strategy": "close"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        size: "small",
                        variant: "text",
                        color: "black"
                      }, props, { icon: "mdi-dots-vertical" }), null, 16)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item, {
                            onClick: ($event: any) => (_ctx.editVM(item)),
                            "prepend-icon": "mdi-pencil-outline",
                            title: "Edit"
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_v_list_item, {
                            onClick: ($event: any) => (_ctx.showActivityWatch(item)),
                            "prepend-icon": "mdi-chart-line",
                            title: "Show Activity Watch"
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_v_list_item, {
                            onClick: ($event: any) => (_ctx.showWazuhDashboard(item)),
                            "prepend-icon": "mdi-shield-check",
                            title: "Show Wazuh Report"
                          }, null, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["items", "headers", "loading", "onClick:row"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        width: "auto",
        modelValue: _ctx.showDialog,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
        onKeypress: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.showDialog = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CreateOrEditVM, {
            teammembers: _ctx.teammembers,
            onCancel: _ctx.cancelRegister,
            onSave: _ctx.save,
            vmModel: _ctx.selectedVM
          }, null, 8, ["teammembers", "onCancel", "onSave", "vmModel"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.showMaintenanceDialog)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            width: "600",
            modelValue: _ctx.showMaintenanceDialog,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showMaintenanceDialog) = $event)),
            onKeypress: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.showMaintenanceDialog = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MaintenanceNotification, {
                teammembers: _ctx.teammembers,
                onCancel: _ctx.cancelRegister,
                onCreateNotification: _ctx.createNotification,
                vmList: _ctx.selectedVmList
              }, null, 8, ["teammembers", "onCancel", "onCreateNotification", "vmList"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}