import { AppState } from ".";
import { state } from "./state";

export const getters = {
  userIsAdmin: (state: AppState) => {
    return (
      state.userInfo &&
      state.userInfo.isAuthenticated &&
      state.userInfo.roles &&
      (state.userInfo.roles === "Admin" || state.userInfo.roles === "Hiringmanager" || state.userInfo.roles === "Support")
    );
  },
  userIsHiringmanager: (state: AppState) => {
    return state.userInfo && state.userInfo.roles && state.userInfo.roles === "Hiringmanager";
  },
  userIsSupport: (state: AppState) => {
    return state.userInfo && state.userInfo.roles && state.userInfo.roles === "Support";
  },
  getSnakbars: (state: AppState) => {
    return state.snakbars;
  },
  getSnakbar: (state: AppState) => (id: any) => {
    return state.snakbars.find((s) => s.id === id);
  },
  updateNotificationListFlag: (state: AppState) => {
    return state.updateNotificationListFlag;
  },
};
