<template>
  <v-row>
    <v-col v-show="!pageLoading" md="12">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <span class="mt-2"> Notification List</span>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mt-3 mr-1" dark @click="newNotif"> <v-icon>mdi-plus</v-icon> New Notice </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="align-center">
            <v-col cols="12" md="2" sm="3" xs="12">
              <DateInputField label="From date" first-day-of-week="1" v-model="startDate" hide-actions placeholder="Date" prepend-icon="" />
            </v-col>
            <v-col cols="12" md="2" sm="3" xs="12">
              <DateInputField label="To date" first-day-of-week="1" v-model="endDate" hide-actions placeholder="Date" prepend-icon="" />
            </v-col>
            <v-col cols="12" md="3">
              <SelectField :items="users" item-title="fullName" item-value="id" v-model="selectedUser" label="User" outlined dense />
            </v-col>
            <v-col cols="6" md="2">
              <v-switch class="pb-0 mt-6" label="Read" v-model="isRead" color="success"></v-switch>
            </v-col>
            <v-col cols="6" md="2">
              <v-btn color="secondary" class="mt-0" @click="clearFilters()"> <v-icon>mdi-close</v-icon> Clear </v-btn>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-text>
        <v-card-text>
          <v-data-table :items="tempNotifications" :headers="headers" :loading="loading">
            <template v-slot:item.ShortDesc="{ item }">
              {{ item.ShortDesc }}
            </template>
            <template v-slot:item.Users="{ item }">
              {{ item.Users ? formatUser(item.Users) : "" }}
            </template>
            <template v-slot:item.Section="{ item }">
              {{ item.Section }}
            </template>
            <template v-slot:item.Seen="{ item }">
              <v-checkbox hide-details readonly v-model="item.Seen" />
            </template>
            <template v-slot:item.CreationDate="{ item }">
              {{ formatDate(item.CreationDate) }}
            </template>
            <template v-slot:item.Schedule="{ item }">
              {{ formatDate(item.Schedule) }}
            </template>
            <template v-slot:item.SeenDate="{ item }">
              {{ formatDate(item.SeenDate) }}
            </template>
            <template v-slot:item.Status="{ item }">
              {{ item.Status }}
            </template>
            <template v-slot:item.Actions="{ item }">
              <div>
                <v-btn class="mt-1" variant="text" size="small" title="show more inforamtion!" @click="showNotifDetail(item)" icon="mdi-magnify" />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false">
      <confirm-dialog :message="confirmModel" @confirm="confirmRemove" @cancel="cancelRemove"></confirm-dialog>
    </v-dialog>
    <v-dialog v-if="showNewNotif" v-model="showNewNotif" persistent fullscreen @keydown.esc="showNewNotif = false">
      <new-notification @cancel="showNewNotif = false" @saved="notifSaved" :users="users"></new-notification>
    </v-dialog>
    <v-dialog v-if="ShowNotifDetailPopUp" v-model="ShowNotifDetailPopUp" persistent @keydown.esc="ShowNotifDetailPopUp = false">
      <notification-detail :notificationItem="selectedNotification" :users="users" @cancel="ShowNotifDetailPopUp = false"></notification-detail>
    </v-dialog>
    <loading-component v-if="pageLoading"></loading-component>
  </v-row>
</template>
<script lang="ts">
import NewNotification from "../Notification/NewNotification.vue";
import NotificationDetail from "../Notification/NotificationDetail.vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import Batch from "shared-components/src/models/Batch";
import Notification from "shared-components/src/models/Notification";
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import NotificationService from "@/services/NotificationService";
import UserService from "@/services/UserService";
import { UserModel, UserOfNotification } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    LoadingComponent,
    NewNotification,
    NotificationDetail,
  },
  data() {
    return {
      userLoading: false,
      pageLoading: true,
      confirmModel: {
        title: "",
        text: "",
      },
      isRead: false,
      startDate: null as Date | null,
      startDatePickerMenu: false,
      endDate: null as Date | null,
      endDatePickerMenu: false,
      users: [] as UserModel[],
      batches: [] as Batch[],
      tempBatches: [] as Batch[],
      notifications: [] as Notification[],
      tempNotifications: [] as Notification[],
      loading: true,
      showNewBatch: false,
      showNewNotif: false,
      showConfirm: false,
      selectedUser: "",
      selectedNotification: {} as Notification,
      selectedItem: {} as Batch,
      showDetailPopUp: false,
      ShowNotifDetailPopUp: false,
      selectedId: null as string | null,
      headers: [
        { title: "Short Desc", sortable: true, value: "ShortDesc" },
        { title: "Users", sortable: true, value: "Users" },
        { title: "Section", sortable: true, value: "Section" },
        { title: "Creation Date", sortable: true, value: "CreationDate" },
        { title: "Schedule Date", sortable: true, value: "Schedule" },
        { title: "Seen Date", sortable: true, value: "SeenDate" },
        { title: "Status", sortable: true, value: "Status" },
        { title: "Seen", sortable: true, value: "Seen" },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
    };
  },
  methods: {
    confirmRemove() {},
    cancelRemove() {},
    showNotifDetail(item: any) {
      this.selectedNotification = item;
      this.ShowNotifDetailPopUp = true;
    },
    formatUser(userList: UserOfNotification[]): string {
      const userNames = [] as string[];
      userList.forEach((element) => {
        userNames.push(this.users.find((item) => item.id == element.UserId)?.fullName ?? "");
      });
      return userNames.join(", ");
    },
    clearFilters() {
      this.selectedUser = "";
      this.startDate = null;
      this.endDate = null;
      this.isRead = false;
    },
    newItem() {
      this.showNewBatch = true;
    },
    newNotif() {
      this.selectedItem = {} as Batch;
      this.showNewNotif = true;
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    showDetail(item: any) {
      this.selectedItem = item;
      this.showDetailPopUp = true;
    },
    async fetchUsers() {
      const usersResponse = await UserService.getAllUsers();
      this.users = usersResponse.filter((c) => c.name && c.name.trim() && c.surname && c.surname.trim());
      this.users.splice(0, 0, { id: "", fullName: "All" } as UserModel);
    },
    async fetchNotifications() {
      this.notifications = await NotificationService.getList();
      this.tempNotifications = this.notifications;
      this.pageLoading = false;
      this.loading = false;
    },
    filterBatches() {
      const sDate = this.startDate;
      const eDate = this.endDate;

      return this.batches.filter((item) => {
        let exist = true;
        if (sDate) {
          exist = item.ScheduledDate >= sDate;
          if (!exist) {
            return;
          }
        }
        if (eDate) {
          exist = item.ScheduledDate <= eDate;
          if (!exist) {
            return;
          }
        }
        if (exist) {
          return item;
        }
      });
    },
    async notifSaved($model: Notification) {
      this.tempNotifications.push($model);
      this.showNewNotif = false;
      await NotificationService.set($model);
      this.fetchNotifications();
    },
    filterNotifications() {
      return this.notifications.filter((item) => {
        let exist = true;
        if (this.startDate) {
          exist = item.CreationDate >= this.startDate;
          if (!exist) {
            return;
          }
        }
        if (this.endDate) {
          exist = item.CreationDate <= this.endDate;
          if (!exist) {
            return;
          }
        }
        if (this.selectedUser) {
          exist = item.Users.some((c: any) => c.UserId === this.selectedUser);
          if (!exist) {
            return;
          }
        }
        if (this.isRead) {
          exist = item.Seen === true;
          if (!exist) {
            return;
          }
        }
        if (exist) {
          return item;
        }
      });
    },
  },
  computed: {
    selectedStartDate: {
      get(): string {
        return this.startDate ? Utils.toVsDateFormat(this.startDate) : "";
      },
      set(date: string | null): void {
        this.startDate = Utils.vsDateToDatetime(date || "");
      },
    },
    selectedEndDate: {
      get(): string {
        return this.endDate ? Utils.toVsDateFormat(this.endDate) : "";
      },
      set(date: string | null): void {
        this.endDate = Utils.vsDateToDatetime(date || "");
      },
    },
  },
  watch: {
    async startDate(newVal) {
      this.tempNotifications = this.filterNotifications();
    },
    async endDate(newVal) {
      this.tempNotifications = this.filterNotifications();
    },
    selectedUser() {
      this.tempNotifications = this.filterNotifications();
    },
    isRead(newVal) {
      this.tempNotifications = this.filterNotifications();
    },
  },
  async mounted() {
    await this.fetchUsers();
    await this.fetchNotifications();
  },
});
</script>
<style lang="scss" scoped>
.register {
  width: 100%;
}
</style>
