export const SET_USER_INFO = "SET_USER_INFO";
export const OPEN_QUICK_LOGIN_DIALOG = "OPEN_QUICK_LOGIN_DIALOG";
export const CLOSE_QUICK_LOGIN_DIALOG = "CLOSE_QUICK_LOGIN_DIALOG";
export const TOGGLE_DRAWER_OPEN = "TOGGLE_DRAWER_OPEN";
export const HANDLE_DRAWER_OPEN = "HANDLE_DRAWER_OPEN";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDDEN_LOADING = "HIDDEN_LOADING";
export const SET_SNAKBAR_MODEL = "SET_SNAKBAR_MODEL";
export const REMOVE_SNAKBAR = "REMOVE_SNAKBAR";
export const TOGGLE_SNAKBAR = "TOGGLE_SNAKBAR";
export const REMOVE_ALL_SNAKBARS = "REMOVE_ALL_SNAKBARS";
export const UPDATE_NOTIFICATION_LIST = "UPDATE_NOTIFICATION_LIST";
