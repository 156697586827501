<template>
  <v-card>
    <v-card-title>
      <v-btn icon @click="closeDialog" class="ml-auto">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container>
        <span class="headline">{{ job.Title }}</span>
      </v-container>

      <v-divider></v-divider>

      <v-container class="py-4">
        <v-row align="center" class="mb-4">
          <v-col cols="auto">
            <v-avatar :color="avatarColor" size="12"></v-avatar>
          </v-col>
          <v-col>
            <span class="task-status"> {{ job.Status }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="task-details">
            <v-icon class="mr-1">mdi-calendar</v-icon>
            <v-tooltip top>
              <template v-slot:activator="{ props }">
                <span class="mr-8" v-bind="props">{{ formatDate(job.StartDate) }}</span>
              </template>
              <span>Start Date</span>
            </v-tooltip>

            <v-icon class="mr-1">mdi-account-multiple</v-icon>
            <v-tooltip top>
              <template v-slot:activator="{ props }">
                <span class="mr-8" v-bind="props">{{ job.NoOfPositions }} positions</span>
              </template>
              <span>Number of Positions</span>
            </v-tooltip>

            <v-icon class="mr-1">mdi-map-marker</v-icon>
            <v-tooltip top>
              <template v-slot:activator="{ props }">
                <span class="mr-8" v-bind="props">{{ getCountryName(job.Country) }}</span>
              </template>
              <span>Country</span>
            </v-tooltip>

            <v-icon class="mr-1">mdi-account-tie</v-icon>
            <v-tooltip top>
              <template v-slot:activator="{ props }">
                <span class="mr-8" v-bind="props">{{ selectedCutomerName }}</span>
              </template>
              <span>Cusomer</span>
            </v-tooltip>
          </v-col>

          <v-col cols="6" class="task-section">
            <span class="task-title">Project Name</span>
            <p>{{ job.ProjectName }}</p>
          </v-col>

          <v-col cols="6" class="task-section">
            <span class="task-title">Skills</span>
            <span v-for="skill in job.Skills" :key="skill.id">{{ skill.Text }}</span>
          </v-col>

          <v-col cols="6" class="task-section">
            <span class="task-title">Job Titles</span>
            <span v-for="title in job.JobTitles" :key="title.id">{{ title.Name }}</span>
          </v-col>

          <v-col cols="6" class="task-section">
            <span class="task-title">Job Role</span>
            <p>{{ selectedJobRole }}</p>
          </v-col>

          <v-col cols="6" class="task-section">
            <span class="task-title">Hiring Contact</span>
            <p>{{ job.HiringContact }}</p>
          </v-col>

          <v-col cols="6" class="task-section">
            <span class="task-title">Hiring Manager</span>
            <p>{{ job.HiringmanagerName }}</p>
          </v-col>

          <v-col cols="12" class="task-section">
            <span class="task-title">Job Description</span>
            <p>{{ job.JobDescription }}</p>
          </v-col>

          <v-col cols="12" class="task-table">
            <v-data-table :headers="headerTask" :items="job.Tasks" :hide-default-footer="true">
              <template v-slot:item.task="{ item }">
                {{ item.Title }}
              </template>
              <template v-slot:item.taskDate="{ item }">
                {{ formatDate(item.Date) }}
              </template>
              <template v-slot:item.order="{ item }">
                {{ item.OrderNumber }}
              </template>
              <template v-slot:item.Description="{ item }">
                {{ item.Description }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { PositionTaskModel, NotificationApi, PositionDetailResponseModel } from "shared-components/src/services/openApi/api";
import Utils from "shared-components/src/utils/Utils";
import TextAreaField from "shared-components/src/components/Fields/TextAreaField.vue";

var notificationApi = new NotificationApi();
export default defineComponent({
  components: { TextAreaField },
  props: {
    job: {
      type: Object as PropType<PositionDetailResponseModel>,
      required: false,
      default: () => ({}),
    },
    selectedCutomerName: {
      type: String,
      required: true,
    },
    selectedJobRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      headerTask: [
        { title: "Task", align: "start", value: "task", sortable: false },
        { title: "Task Date", align: "center", value: "taskDate", sortable: false },
        { title: "Task Order", align: "center", value: "order", sortable: false },
      ],
    };
  },
  async mounted() {
    console.log(this.job);
    await this.getCommentsData();
  },
  setup(props) {
    const avatarColor = computed(() => {
      return props.job.Status === "Active" ? "primary" : "grey";
    });

    return {
      avatarColor,
    };
  },
  methods: {
    formatDate(date: any): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    getCountryName(country: any): string {
      return Array.isArray(country) ? country.join(", ") : country;
    },
    async getCommentsData() {
      const comment = await notificationApi.getPositionTaskNotifications("positionTaskId");
    },
  },
});
</script>

<style scoped>
.task-table {
  border: 2px solid #ccc;
}

.headline {
  font-weight: bold;
  padding-left: 8px;
  font-size: 1.5rem;
}

.task-status {
  font-weight: bold;
  font-size: 1.2rem;
}

.task-details {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 1rem;
}

.task-section {
  margin-top: 16px;
}

.task-title {
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}
</style>
