<template>
  <div><activity-watch-report :vmId="vmId" @Close="goBack" /></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ActivityWatchReport from "shared-components/src/components/Reports/ActivityWatch/ActivityWatchReport.vue";

export default defineComponent({
  components: {
    ActivityWatchReport,
  },
  data() {
    return {
      vmId: this.$route.params.id, //this is the id from the browser
    };
  },
  methods: {
    goBack(){
      window.location.href = `/vm`;
    }
  }
});
</script>
