<template>
  <v-card :loading="pageLoading" :disabled="pageLoading">
    <v-card-title>
      <v-row no-gutters>
        <span> Reset password</span>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form v-if="!resetLinkSent && !token" ref="frmResetPass" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="3" sm="6">
            <TextField :rules="[rules.required, rules.email]" v-model="email" label="Email" placeholder="Email" outlined></TextField>
          </v-col>
          <v-col md="3" sm="6">
            <v-btn :loading="loading" class="primary pt-3 mt-9 pb-7" @click="reset"><v-icon>mdi-email</v-icon> Reset</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div v-if="token && email">
        <v-form ref="frmNewPass" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="3" sm="6">
              <TextField :rules="[rules.required, rules.email]" v-model="email" label="Email" placeholder="Email" outlined readonly></TextField>
            </v-col>
            <v-col md="3" sm="6">
              <PasswordField
                :rules="[rules.required]"
                v-model="newPassword"
                label="Enter New Password"
                placeholder="Enter New Password"
                outlined
              ></PasswordField>
            </v-col>
            <v-col md="3" sm="6">
              <v-btn :loading="loading" class="primary pt-7 pb-7" @click="saveNewPassword"><v-icon>mdi-email</v-icon> Save </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div v-if="token && !email && !pageLoading" class="text-center">There is an error on Validating Token</div>
      <v-row v-if="resetLinkSent">
        <v-col md="12">
          <h3>Please check your email ({{ email }}), reset password link was sent to you mail.</h3>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import UserInfo from "shared-components/src/models/UserInfo";
import UserService from "@/services/UserService";
import AppHelper from "shared-components/src/utils/AppHelper";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      valid: false,
      loading: false,
      pageLoading: false,
      resetLinkSent: false,
      email: null as string | null,
      newPassword: null as string | null,
      showPassword: false,
      rules: {
        required: (value: any) => !!value || "Required.",
        email: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid.",
      },
      token: null as string | null,
      callbackUrl: `${window.location.origin}/resetPassword`,
    };
  },
  methods: {
    async reset() {
      const isValid = await (this.$refs.frmResetPass as any).validate();
      if (isValid.valid) {
        this.sendResetLink();
      }
    },
    async saveNewPassword() {
      const isValid = await (this.$refs.frmNewPass as any).validate();
      if (isValid.valid && this.newPassword && this.token) {
        this.pageLoading = true;
        UserService.saveNewPassword(this.newPassword, this.token)
          .then((result) => {
            this.$router.push("/login");
          })
          .catch((err) => {
            store.dispatch("showErrorMessage", err);
          })
          .finally(() => {
            this.pageLoading = true;
          });
      }
    },
    sendResetLink() {
      if (this.email) {
        this.loading = true;
        UserService.resetPassword(this.email, this.callbackUrl)
          .then((result) => {
            this.resetLinkSent = true;
          })
          .catch((err) => {
            this.resetLinkSent = false;
            store.dispatch("showErrorMessage", err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    if (AppHelper.IsAzureEnv()) {
      this.token = this.$route.query.token as string;
      if (this.token) {
        this.pageLoading = true;
        UserService.validateResetToken(this.token)
          .then((result) => {
            this.email = result;
          })
          .catch((err) => {
            store.dispatch("showErrorMessage", err);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      }
    }
  },
  computed: {
    userInfo(): UserInfo {
      return store.state.userInfo as UserInfo;
    },
  },
});
</script>
