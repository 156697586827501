<template>
  <v-card>
    <v-card-title> New Maintenance Notification</v-card-title>
    <v-card-text>
      <v-form ref="frmRegisterNotif" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="12">
            <AutoCompleteField v-model="selectedTeammembers" :items="teammemberList" item-title="FullName"
              item-value="UserAccountsId"
              multiple chips outlined dense label="Team Member">
            </AutoCompleteField>
          </v-col>
          <v-col cols="12">
            <TextAreaField :rules="[rules.required]" v-model="model.Text" showRequired outlined label="Text"
              placeholder="Notification text" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn color="secondary" class="mr-10" @click="save" :loading="loading"><v-icon>mdi-check</v-icon> Save</v-btn>
        <v-btn color="primary" @click="cancel"> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import { VM } from "shared-components/src/models/VM";
import { Teammember } from "shared-components/src/models/Teammember";
import rules from "shared-components/src/utils/validations";
import { TeammemberOfNotification } from "shared-components/src/models/Notification";
import { NotificationSection, NotificationType } from "shared-components/src/definitions/constants";
import { UserModel, UserOfNotification } from "shared-components/src/services/openApi/api";

export default defineComponent({
  props: ["vmList", "teammembers"],
  mounted() {
    if (this.vmList && this.vmList && this.vmList.length > 0) {
      this.list = this.vmList as VM[];
      var teammemberIds = this.vmList.map((c:any) => c.OwnerId);
      var selectedTeammembers = this.teammembers?.filter((c:any) => teammemberIds.includes(c.Id));
      if(selectedTeammembers)
      {
        this.selectedTeammembers = selectedTeammembers.map((c: any) => c.UserAccountsId);
      }
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      selectedTeammembers: [] as string[],
      teammemberList: this.teammembers.filter((x:any) => x.FullName != "All"),
      endMenu: false,
      list: [] as VM[],
      model: {
        PushText: "",
        Text: "",
        ShortDesc: "",
        CreationDate: {} as Date,
        Section: NotificationSection.Other,
        SmsText: "",
        EmailText: "",
        Users: [] as UserOfNotification[],
        Type: NotificationType.Message,
        SendNow: true,
        Status: "",
      },
      rules,
    };
  },
  methods: {
    cancel(): void {
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmRegisterNotif as any).validate();
      if (isValid.valid && this.selectedTeammembers) {
        this.loading = true;
        var notifStatus = "Pending";
        this.model.EmailText = this.model.Text;
        const allTm = this.selectedTeammembers.some((st) => !st);
        if (allTm) {
          this.selectedTeammembers = this.teammembers;
        }
        if (this.selectedTeammembers) {
          this.selectedTeammembers.forEach((c) => {
            var userItem = { UserId: c, Status: notifStatus } as UserOfNotification;
            this.model.Users.push(userItem);
          });
        }
        this.model.CreationDate = new Date();
        this.model.Status = notifStatus;
        await this.$emit("createNotification", this.model);
      }
    },
  },
});
</script>