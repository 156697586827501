import {
  Teammember,
  TeammemberSkill,
  Education,
  Certificate,
  LanguageSkill,
  Address,
  Phone,
  ProfessionalDetail,
  ProfessionalDetailCareerObjectiveModel,
} from "shared-components/src/models/Teammember";
import { TeamMenberOfCommitment } from "shared-components/src/models/Commitment";
import ApiService from "./ApiService";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";
import { Contact } from "shared-components/src/models/Contact";
import Utils from "shared-components/src/utils/Utils";
import RecaptchaResponse from "shared-components/src/models/RecaptchaResponse";
import { PersonalDetailModel } from "shared-components/src/models/PersonalDetailModel";

export default class TeamMemberService {
  public static getList(): Promise<Teammember[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember`, "")
        .then((tmResult) => {
          if (!tmResult.data) {
            resolve([]);
          } else {
            const retVal = tmResult.data.map((d: any) => {
              const tm = { Id: d.id, ...d } as Teammember;
              tm.FullName = `${tm.FirstName || ""} ${tm.LastName || ""}`;
              return tm;
            });
            resolve(retVal);
          }
        })
        .catch((tmError) => reject("Team member error => " + tmError));
    });
  }

  public static getById(id: string): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/GetById/${id}`, "")
        .then((tmResult) => {
          if (tmResult.data) {
            resolve({ Id: tmResult.data.id, ...tmResult.data } as Teammember);
          } else {
            resolve(null);
          }
        })
        .catch((tmError) => reject(`error in TeamMemberService.getById -> ${tmError}`));
    });
  }

  private static getVerificationResponseList(res: any) {
    if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
      return [];
    } else {
      var result = this.convertResponse(res.data.value);
      return result;
    }
  }

  private static convertResponse(result: any) {
    if (result && result.length > 0) {
      result.forEach((c: any) => {
        if (c) {
          if (c.StartTimerDate) {
            c.StartTimerDate = new Date(c.StartTimerDate);
          }
          if (c.EndTimerDate) {
            c.EndTimerDate = new Date(c.EndTimerDate);
          }
          if (c.VerifiedOn) {
            c.VerifiedOn = new Date(c.VerifiedOn);
          }
        }
      });
      return result;
    }
  }

  private static getContactResponse(res: any) {
    if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
      return [];
    } else {
      res.data.value.Addresses = this.convertResponse(res.data.value.Addresses);
      res.data.value.PhoneNumbers = this.convertResponse(res.data.value.PhoneNumbers);
      return res.data.value;
    }
  }

  public static getByProjectLeadId(projectLeadId: any): Promise<TeamMenberOfCommitment[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/getTeammemberByProjectLead/${projectLeadId}`, "")
        .then((tmResult) => {
          if (!tmResult.data) {
            resolve([]);
          } else {
            const retVal = tmResult.data.map((d: any) => {
              const tm = { Id: d.id, ...d } as TeamMenberOfCommitment;
              return tm;
            });
            resolve(retVal);
          }
        })
        .catch((tmError) => reject("Team member error => " + tmError));
    });
  }

  public static verifyAddress(address: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      var verificationCode = address.VerificationCode;
      ApiService.post(`/teammember/verifyAddress/${address.Id}`, { verificationCode })
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static setAddressAsDefault(addressId: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/address/setAsDefault/${addressId}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static sendAddressCode(id: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/sendAddressVerificationCode/${id}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getContact(): Promise<Contact | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/contact`, "")
        .then((res) => {
          resolve(this.getContactResponse(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getResumeDownloadLink(): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/downloadProfileCv`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getLanguageSkills(): Promise<LanguageSkill[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/languageSkills`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value as LanguageSkill[]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getExperienceDetail(): Promise<ProfessionalDetail | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/experienceDetail`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value as ProfessionalDetail);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static uploadResume(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.upload(`/teammember/uploadProfileCv`, file)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getEducations(): Promise<Education[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/educations`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            var response = res.data.value;
            response.forEach((item: any) => {
              if (item.StartDate) {
                item.StartDate = new Date(item.StartDate);
              }
              if (item.EndDate) {
                item.EndDate = new Date(item.EndDate);
              }
            });
            resolve(response as Education[]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getCertificates(): Promise<Certificate[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/certificates`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value as Certificate[]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static uploadSkillCv(skillId: any, file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.upload(`/teammember/uploadSkillCv/${skillId}`, file)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async setLanguageSkill(model: LanguageSkill): Promise<LanguageSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.post("/teammember/saveLanguageSkill", model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as LanguageSkill[]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async deleteLanguageSkill(id: any): Promise<LanguageSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/removeLanguageSkill/${id}`)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as LanguageSkill[]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private static getDataFromResponse(list: any) {
    const retVal = list.map((doc: any) => {
      const item = { id: doc.Id, ...doc };
      if (item.EndDate && doc.EndDate) {
        item.EndDate = new Date(doc.EndDate);
        item.EndDate = Utils.removeTimezoneOffset(item.EndDate);
      }
      if (item.StartDate) {
        item.StartDate = new Date(doc.StartDate);
        item.StartDate = Utils.removeTimezoneOffset(item.StartDate);
      }
      return item;
    });

    return retVal;
  }

  public static async setEducation(model: Education): Promise<Education[]> {
    return new Promise((resolve, reject) => {
      var startDate = Utils.addTimezoneOffsetToStartDate(model.StartDate);
      var endDate = Utils.addTimezoneOffsetToEndDate(model.EndDate);

      if (startDate) {
        model.StartDate = startDate;
      }
      if (endDate) {
        model.EndDate = endDate;
      }
      ApiService.post("/teammember/saveEducation", model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as Education[]);
          } else {
            var retval = this.getDataFromResponse(res.data.value) as Education[];
            resolve(retval);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async deleteEducation(id: any): Promise<Education[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/removeEducation/${id}`)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as Education[]);
          } else {
            var retval = this.getDataFromResponse(res.data.value) as Education[];
            resolve(retval);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static verifyPhone(phone: Phone): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      var verificationCode = phone.VerificationCode;
      ApiService.post(`/teammember/verifyPhone/${phone.Id}`, { verificationCode })
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static saveAddress(address: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/address`, address)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async verifyCaptcha(captchaToken: any): Promise<RecaptchaResponse> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/verifyCaptcha`, { captchaToken: captchaToken })
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve({} as RecaptchaResponse);
          } else {
            const result = { ...res.data.value } as RecaptchaResponse;
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async setCertificate(model: Certificate): Promise<Certificate> {
    return new Promise((resolve, reject) => {
      ApiService.post("/teammember/saveCertificate", model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve({} as Certificate);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async deleteCertificate(id: any): Promise<Certificate[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/removeCertificate/${id}`)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as Certificate[]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static savePersonalDetail(model: PersonalDetailModel): Promise<PersonalDetailModel | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/personalDetail`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static saveExperienceDetail(model: ProfessionalDetail): Promise<ProfessionalDetail | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/experienceDetail`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static removeExperienceCareerObjectiveDetail(id: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/experienceCareerObjectiveDetail/${id}`)
        .then((res) => {
          resolve(res.data.value);
        })
        .catch((err) => {
          reject(err.data.value);
        });
    });
  }

  public static saveExperienceCareerObjectiveDetail(
    model: ProfessionalDetailCareerObjectiveModel
  ): Promise<ProfessionalDetailCareerObjectiveModel | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/experienceCareerObjectiveDetail`, model)
        .then((res) => {
          resolve(res.data.value);
        })
        .catch((err) => {
          resolve(err.data.value);
        });
    });
  }

  public static updateSkill(skillModel: any): Promise<TeammemberSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/saveSkill`, skillModel)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static removeSkill(skillId: any): Promise<TeammemberSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/removeSkill/${skillId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static savePhone(phone: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/phone`, phone)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static removePhone(phoneId: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/phone/${phoneId}`)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private static getTeammemberResponse(result: any) {
    var item = { ...result } as Teammember;
    item.BirthDate = new Date(result.BirthDate);
    item.BirthDate = Utils.removeTimezoneOffset(item.BirthDate);
    if (item.UpdatedPaymentMethodDate) {
      item.UpdatedPaymentMethodDate = new Date(result.UpdatedPaymentMethodDate);
      item.UpdatedPaymentMethodDate = Utils.removeTimezoneOffset(item.UpdatedPaymentMethodDate);
    }
    item.Educations?.forEach((item) => {
      if (item.StartDate) {
        item.StartDate = new Date(item.StartDate);
      }
      if (item.EndDate) {
        item.EndDate = new Date(item.EndDate);
      }
    });
    return { ...result } as Teammember;
  }

  public static getPersonalDetail(): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/personalDetails`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            var model = this.getTeammemberResponse(res.data.value);
            resolve(model);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getSkillDownloadLink(skillId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/downloadSkillCv/${skillId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static sendPhoneCode(id: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/sendPhoneVerificationCode/${id}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static setPhoneAsDefault(phoneId: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/phone/setAsDefault/${phoneId}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static removeAddress(addressId: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/address/${addressId}`)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static saveRoleAndTitle(details: any): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/roleAndTitle`, details)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
