<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="pb-0">
          Audit Trail List
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="filters pb-0" cols="12" md="4" sm="3" xs="12">
              <ComboBoxField
                :items="users"
                item-title="displayName"
                item-value="id"
                v-model="selectedUsers"
                label="Users"
                :loading="userLoading"
                multiple
                outlined
                dense
              ></ComboBoxField>
            </v-col>
            <v-col class="filters pb-0" cols="12" md="3" sm="3" xs="12">
              <DateInputField
                first-day-of-week="1"
                v-model="startDate"
                label="From Date"
                @update:modelValue="startDatePickerMenu = false"
                hide-actions
                placeholder="Select Date"
                prepend-icon=""
              ></DateInputField>
            </v-col>
            <v-col class="filters pb-0" cols="12" md="3" sm="3" xs="12">
              <DateInputField
                first-day-of-week="1"
                v-model="endDate"
                @update:modelValue="endDatePickerMenu = false"
                hide-actions
                placeholder="Select Date"
                prepend-icon=""
                label="To Date"
              >
              </DateInputField>
            </v-col>
            <v-col class="filters pb-0 mt-9" md="1">
              <v-btn class="secondary" @click="clearFilters()"> <v-icon>mdi-close</v-icon> Clear </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-data-table class="pt-0" :items="filteredAuditTrail" :loading="auditLoading" :headers="headers">
            <template v-slot:item.DateTime="{ item }">
              <strong>{{ getDate(item.DateTime) }}</strong>
            </template>
            <template v-slot:item.Event="{ item }">
              <span>{{ item.Event }}</span>
            </template>
            <template v-slot:item.RecordType="{ item }">
              <span>{{ item.RecordType.Type }}</span>
            </template>
            <template v-slot:item.UserId="{ item }">
              {{ getUserName(item.UserId) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import AuditTrail from "shared-components/src/models/AuditTrail";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import UserService from "@/services/UserService";
import Utils from "shared-components/src/utils/Utils";
import { UserModel } from "shared-components/src/services/openApi";

export default defineComponent({
  data() {
    return {
      auditLoading: true,
      userLoading: true,
      startDate: null as Date | null,
      endDate: null as Date | null,
      endDatePickerMenu: false,
      startDatePickerMenu: false,
      selectedUsers: [] as string[],
      users: [] as UserModel[],
      auditTrails: [] as AuditTrail[],
      filteredAuditTrail: [] as AuditTrail[],
      headers: [
        { title: "Date", align: "start", value: "DateTime", sortable: false },
        { title: "Event", value: "Event", sortable: false },
        { title: "Record Type", value: "RecordType", sortable: false },
        { title: "User", value: "UserId", sortable: false },
      ],
    };
  },
  methods: {
    getUserName(userId: any) {
      var user = this.users.find((c) => c.id === userId);
      if (user) {
        return user.displayName;
      } else {
        return "";
      }
    },
    getDate(date: any) {
      if (date) {
        const dateTime = Utils.timestampToDate(date);
        const dateFromat = Utils.toVsDateFormat(dateTime);
        if (!dateFromat.includes("NaN")) {
          return dateFromat;
        } else {
          const convertedDate = Utils.toVsDateFormat(date);
          return convertedDate;
        }
      }
      return "";
    },
    clearFilters() {
      this.selectedUsers = [];
      this.startDate = null;
      this.endDate = null;
    },
    async filterByDateRange(fromDate: any, toDate: any) {
      this.auditLoading = true;
      this.filteredAuditTrail = await AuditTrailService.getByDateRange(fromDate, toDate);
      this.auditLoading = false;
    },
  },
  computed: {
    formatedStartDate() {
      return this.startDate ? Utils.toVsDateFormat(this.startDate) : null;
    },
    formatedEndDate() {
      return this.endDate ? Utils.toVsDateFormat(this.endDate) : null;
    },
  },
  async mounted() {
    this.auditTrails = await AuditTrailService.getList();
    this.filteredAuditTrail = this.auditTrails;
    this.auditLoading = false;
    const allUsers = await UserService.getAllUsers();
    if (allUsers && allUsers.length > 0) {
      this.users = allUsers
        .filter((c) => c.id && (c.displayName || c.email))
        .map((c) => {
          return { id: c.id, displayName: c.displayName ? c.displayName : c.email };
        });
    }
    this.userLoading = false;
  },
  watch: {
    async startDate(newVal) {
      await this.filterByDateRange(newVal, this.endDate);
      if (this.selectedUsers && this.selectedUsers.length > 0) {
        this.filteredAuditTrail = this.filteredAuditTrail.filter((c) => this.selectedUsers.includes(c.UserId));
      }
    },
    async endDate(newVal) {
      if (newVal || this.startDate) {
        await this.filterByDateRange(this.startDate, newVal);
        if (this.selectedUsers && this.selectedUsers.length > 0) {
          this.filteredAuditTrail = this.filteredAuditTrail.filter((c) => this.selectedUsers.includes(c.UserId));
        }
      }
    },
    async selectedUsers(newVal) {
      if (newVal && newVal.length > 0) {
        const selectedIds = newVal.map((x: any) => x.id);
        if (this.startDate || this.endDate) {
          this.filteredAuditTrail = this.filteredAuditTrail.filter((c) => selectedIds.includes(c.UserId));
        } else {
          this.filteredAuditTrail = this.auditTrails.filter((c) => selectedIds.includes(c.UserId));
        }
      } else {
        if (this.startDate || this.endDate) {
          await this.filterByDateRange(this.startDate, this.endDate);
        } else {
          this.filteredAuditTrail = this.auditTrails;
        }
      }
    },
  },
});
</script>
